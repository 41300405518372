import React, { useState } from 'react';
import { Dropdown, Textarea } from 'rizzui';
import { Modal } from 'rizzui';
import { StateOption, StateOptions } from '../../type/entities/state-type';
import { IoArrowDown } from 'react-icons/io5';
import cn from '../../utils/classNames';
import { CustomBadge } from './CustomBadge';
import moment from 'moment';

interface StatusDropdownProps {
    entityId: string;
    currentState: any;
    currentStateDate?: string;
    statesOptions: StateOptions;
    title: string;
    dropdownClassName?: string;
    badgeSize?: "sm" | "md" | "lg" | "xl" | undefined;
    badgeRounded?: "none" | "sm" | "md" | "lg" | "pill" | undefined;
    modalSize?: 'sm' | 'md' | 'lg' | 'full';
    cancelBtnText?: string;
    confirmBtnText?: string;
    handleStateChange: (invoiceId: string, newStateId: string, comment: string) => void;
}

const StatusDropdown: React.FC<StatusDropdownProps> = ({
    title,
    statesOptions = [],
    entityId,
    currentState,
    currentStateDate,
    dropdownClassName,
    badgeSize = 'lg',
    badgeRounded = 'md',
    modalSize = 'md',
    cancelBtnText = 'Cancelar',
    confirmBtnText = 'Confirmar',
    handleStateChange
}) => {
    const [showModal, setShowModal] = useState(false);
    const [selectedState, setSelectedState] = useState<StateOption | null>(statesOptions.find((state) => state.value === currentState.id) || null);

    // ---------------------------------------------------
    // DROPDOWN MENU ACTIONS
    // ---------------------------------------------------
    const handleStateSelect = (state: StateOption) => {
        setSelectedState(state);
        setShowModal(true);
    };

    // ---------------------------------------------------
    // MODAL MENU ACTIONS
    // ---------------------------------------------------

    const handleConfirm = () => {
        const textareaValue = document.getElementById('comments') as HTMLInputElement;;
        setShowModal(false);
        if (selectedState) {
            handleStateChange(entityId, selectedState.value, textareaValue?.value || '');
        }
    };

    const handleCancel = () => {
        setSelectedState(statesOptions.find((state) => state.value === currentState.id) || null);
        setShowModal(false);
    };

    return (
        <div>
            <Dropdown placement="bottom-end" className={cn('w-full', dropdownClassName)} key={entityId}>
                <Dropdown.Trigger className='w-full'>
                    <CustomBadge
                        className="cursor-pointer w-full"
                        rounded={badgeRounded}
                        size={badgeSize}
                        customColor={selectedState?.color || currentState.color || 'primary'}
                    >
                        <div className="flex flex-col justify-center items-center text-nowrap leading-relaxed">
                            {selectedState?.label || currentState.name || 'Seleccionar Estado'} <br />
                            {currentStateDate && (moment(currentStateDate).format('DD/MM/YY HH:mm'))}
                        </div>
                    </CustomBadge>
                </Dropdown.Trigger>
                <Dropdown.Menu className="max-h-80 md:max-h-50 w-auto overflow-auto shadow-xl">
                    {statesOptions && statesOptions.length >= 1 && statesOptions.map((state) => (
                        <Dropdown.Item key={state.value} onClick={() => handleStateSelect(state)} className='w-full' >
                            <CustomBadge className="cursor-pointer w-full" rounded={badgeRounded} size={badgeSize || 'lg'} customColor={state.color || 'primary'} >
                                {state.label}
                            </CustomBadge>
                        </Dropdown.Item>
                    ))}
                    { (!statesOptions || statesOptions.length === 0) && (
                        <div className='px-4 py-2'>Sin resultados...</div>
                    )}
                </Dropdown.Menu>
            </Dropdown>

            {showModal && (
                <Modal isOpen={showModal} onClose={() => { }} size={modalSize}>
                    <div className='flex flex-col justify-center p-2'>
                        <h4 className="text-2xl text-center">{title || '¿Estas seguro de cambiar el estado de la factura?'}</h4>
                    </div>
                    <div className='flex flex-col gap-3'>
                        <CustomBadge
                            className="cursor-pointer m-auto"
                            rounded={badgeRounded}
                            size={badgeSize}
                            customColor={currentState?.color || 'primary'}
                        >
                            {currentState.name || 'Seleccionar Estado'}
                        </CustomBadge>
                        <IoArrowDown className="text-md text-black m-auto" />
                        <CustomBadge
                            className="cursor-pointer m-auto"
                            rounded={badgeRounded}
                            size={badgeSize}
                            customColor={selectedState?.color || 'primary'}
                        >
                            {selectedState?.label || 'Seleccionar Estado'}
                        </CustomBadge>
                    </div>
                    <Textarea
                        id="comments"
                        label="Comentario"
                        placeholder="Escribe un comentario"
                        rows={3}
                        className="col-span-1 md:col-span-2 [&>label>span]:font-medium p-4"
                    />

                    <div className="flex justify-center p-3 gap-4">
                        <button className="px-4 py-2 bg-primary text-white rounded-md hover:bg-primary-300" onClick={handleConfirm}>
                            {confirmBtnText}
                        </button>
                        <button className="px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600" onClick={handleCancel}>
                            {cancelBtnText}
                        </button>
                    </div>
                </Modal>
            )}
        </div>
    );
};

export default StatusDropdown;