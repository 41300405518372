import { Outlet } from "react-router-dom";
import { ContractProvider } from "./providers/ContractProvider";

const ContractsLayoutWrapper = () => {
    return (
        <div className="flex flex-grow flex-col px-4 pt-0 pb-4 md:px-5 lg:px-6 lg:pb-8 3xl:px-8  3xl:pt-0 4xl:px-10">
            <ContractProvider>
                <Outlet />
            </ContractProvider>
        </div>
    )
}

export default ContractsLayoutWrapper;