import React, { useCallback } from 'react';
import { useFiltersPR } from '../../../components/providers/FiltersProvider';
import useFetch from '../../../hooks/useFetch';
import { ConsultancyService } from '../../../services/consultancy/consultancyService';
import { ConsultanciesApiResponse } from '../../../type/entities/consultancy-type';
import useHandleErrors from '../../../hooks/useHandleErrors';
import { toast } from 'sonner';
import { ActionIcon, Tooltip } from 'rizzui';
import Page from '../../../layout/Page/Page';
import CustomTable from '../../../components/table/CustomTable';
import { Link } from 'react-router-dom';
import { menuRoutes } from '../../../router/menu';
import { MdOutlineModeEditOutline } from 'react-icons/md';
import DeletePopover from '../../../components/buttons/DeletePopover';

interface ConsultanciesListProps { }

const columnOrder = [
    'id',
    'name',
    'email',
    'contact',
    'telephone',
    'description',
    'createdAt',
    'updatedAt',
];

const ConsultanciesList: React.FC<ConsultanciesListProps> = ({ }) => {

    const { handleErrors } = useHandleErrors();
    const { filters, updateFilters, updateFilterOrder, updatePage, updatePageSize } = useFiltersPR();

    const [data, loading, error, refetch] = useFetch(useCallback(async () => {
        const response = await (new ConsultancyService).listConsultancies(filters);
        return response.getResponseData() as ConsultanciesApiResponse;
    }, [filters]));

    const handleDelete = async (id: string) => {
        const response = await (await (new ConsultancyService).deleteConsultancy(id)).getResponseData();
        if (response.success) {
            toast.success('Asesoría eliminada correctamente');
            refetch();
        } else {
            handleErrors(response);
        }
    };

    const handleMultiDelete = async (ids: string[]) => {
        const response = await (await (new ConsultancyService).deleteMultiConsultancies(ids)).getResponseData();
        if (response.success) {
            toast.success('Asesorías eliminadas correctamente');
            refetch();
        } else {
            handleErrors(response);
        }
    };

    /**
     * Order the data to match the column order
     */
    const orderedData = () => data?.data.map((row: any) => {
        const orderedRow: any = {};
        columnOrder.forEach((key) => {
            orderedRow[key] = row[key];
        });
        return orderedRow;
    });

    return (
        <Page container='fluid'>
            {data !== undefined && (
                <CustomTable
                    id={'consultancies-table'}
                    filter={false}
                    columnOrder={columnOrder}
                    data={orderedData()}
                    isLoading={loading}
                    columnsNotShown={[]}
                    actions={[
                        {
                            label: 'Edit',
                            permissions: { group: 'consultancies', permission: 'edit_consultancies' },
                            render: (row: any) => {
                                return (
                                    <Tooltip
                                        size="sm"
                                        content={'Editar asesoría'}
                                        placement="top"
                                        color="invert"
                                    >
                                        <Link to={`${menuRoutes.consultancies.path}/${row.id}/edit`}>
                                            <ActionIcon
                                                as="span"
                                                size="sm"
                                                variant="outline"
                                                className="hover:!border-gray-900 hover:text-gray-700"
                                            >
                                                <MdOutlineModeEditOutline className="h-4 w-4" size={20} />
                                            </ActionIcon>
                                        </Link>
                                    </Tooltip>
                                );
                            },
                        },
                        {
                            label: 'Delete',
                            permissions: { group: 'consultancies', permission: 'delete_consultancies' },
                            render: (row: any) => {
                                return (
                                    <Tooltip
                                        size="sm"
                                        content={'Eliminar'}
                                        placement="top"
                                        color="invert"
                                    >
                                        <div>
                                            <DeletePopover
                                                title={`Eliminar asesoría`}
                                                description={`¿Estás seguro de que deseas eliminar ${row.name}?`}
                                                onDelete={() => handleDelete(row.id)}
                                            />
                                        </div>
                                    </Tooltip>
                                );
                            },
                        },
                    ]}
                    handleMultipleDelete={handleMultiDelete}
                    filters={filters}
                    updateFilters={updateFilters}
                    updateFilterOrder={updateFilterOrder}
                    defaultOrder={filters.filter_order || undefined}
                    paginationData={{
                        pageSize: filters.limit,
                        currentPage: filters.page,
                        pageCount: (data as ConsultanciesApiResponse) ? data.lastPage : 1,
                        totalCount: data?.totalRegisters,
                        handlePagination: updatePage,
                        handlePerPage: updatePageSize,
                    }}
                />
            )}
        </Page>
    );
};

export default ConsultanciesList;