import React, { useCallback, useState } from 'react';
import { toast } from 'sonner';
import { StateService } from '../../../../services/state/stateService';
import useFetch from '../../../../hooks/useFetch';
import { StatesApiResponse } from '../../../../type/entities/state-type';
import { Link } from 'react-router-dom';
import CustomTable from '../../../../components/table/CustomTable';
import { ActionIcon, Switch, Tooltip } from 'rizzui';
import { menuRoutes } from '../../../../router/menu';
import { MdOutlineModeEditOutline } from 'react-icons/md';
import DeletePopover from '../../../../components/buttons/DeletePopover';
import useHandleErrors from '../../../../hooks/useHandleErrors';
import { IoFileTrayFull, IoFileTrayFullOutline, IoStar } from 'react-icons/io5';
import { IoMdStarOutline } from 'react-icons/io';
import { FaThumbsUp, FaThumbsDown, FaRegThumbsUp, FaRegThumbsDown } from "react-icons/fa";
import { TbFileInvoice } from 'react-icons/tb';
import StatusToggle from '../../../../components/buttons/StatusToggle';
import OrderControl from '../../../../components/buttons/OrderControl';
import { FaFileInvoice } from 'react-icons/fa6';

interface FilteredStatesTableProps {
    entity: string;
    filters: any;
    updateFilters: any;
    updateFilterOrder: any;
    updatePage: any;
    updatePageSize: any;
    openFilters: boolean;
    setOpenFilters: any;
}

const columnOrder = [
    'id',
    'statusOrder',
    'name',
    'color',
    'draftStatus',
    'initialStatus',
    'satisfactoryStatus',
    'unsatisfactoryStatus',
    'clientInvoiceStatus',
    'tutorInvoiceStatus',
    'internalInvoiceStatus',
    'productType',
    'description',
    'active',
    'createdAt',
    'updatedAt',
];

const FilteredStatesTable: React.FC<FilteredStatesTableProps> = ({ entity, filters, updateFilters, updateFilterOrder, updatePage, updatePageSize, openFilters, setOpenFilters }) => {

    const { handleErrors } = useHandleErrors();
    const service = new StateService();

    const [changingStatus, setChangingStatus] = useState<string[]>([]);

    const [data, loading, error, refetch] = useFetch(useCallback(async () => {
        if (filters.filter_filters?.entity === undefined) return;
        const response = await service.listStates(filters);
        return response.getResponseData() as StatesApiResponse;
    }, [filters]));

    const updateStateOrder = async (id: string, order: 'up' | 'down') => {
        const response = await (await service.updateStateOrder(id, order)).getResponseData();

        if (response.success) {
            toast.success('Orden actualizado');
            refetch();
        } else {
            handleErrors(response);
        }
    };

    /**
     * Toggle the state of the status
     */
    const toggleState = async (id: string, state: boolean) => {
        try {
            setChangingStatus([...changingStatus, id]);
            const response = (await service.toggleStateStatus(id, state)).getResponseData();
            if (response.success) {
                setChangingStatus(changingStatus.filter((item) => item !== id));
                refetch();
                toast.success(state ? "Se ha desactivado el estado" : "Se ha activado el estado");
            } else {
                setChangingStatus(changingStatus.filter((item) => item !== id));
                toast.error(response.message);
            }
        } catch (error: any) {
            setChangingStatus(changingStatus.filter((item) => item !== id));
            toast.error(error.message);
        }
    };

    const generateMessage = (status: string, success: boolean): string => {
        const baseMessage = `Estado ${success ? 'establecido' : 'no se ha podido establecer'} como`;
        const statusDescriptions: { [key: string]: string } = {
            draft: 'borrador',
            initial: 'inicial',
            satisfactory: 'satisfactorio',
            unsatisfactory: 'insatisfactorio',
            clientInvoiceStatus: 'generador de factura de cliente',
            tutorInvoiceStatus: 'generador de factura de tutor',
            internalInvoiceStatus: 'generador de factura interna',
        };
        return `${baseMessage} ${statusDescriptions[status]}`;
    };

    /**
     * Toggle individual status
     */
    const toggleStatus = async (id: string, stateType: 'draft' | 'initial' | 'satisfactory' | 'unsatisfactory' | 'clientInvoiceStatus' | 'tutorInvoiceStatus' | 'internalInvoiceStatus') => {
        const stateServiceMap: { [key: string]: () => Promise<any> } = {
            draft: () => service.toggleDraftState(id),
            initial: () => service.toggleInitialState(id),
            satisfactory: () => service.toggleSatisfactoryState(id),
            unsatisfactory: () => service.toggleUnsatisfactoryState(id),
            clientInvoiceStatus: () => service.toggleClientInvoiceState(id),
            tutorInvoiceStatus: () => service.toggleTutorInvoiceState(id),
            internalInvoiceStatus: () => service.toggleInternalInvoiceState(id),
        };

        try {
            const response = await (await stateServiceMap[stateType]()).getResponseData();

            if (response.success) {
                toast.success(generateMessage(stateType, true));
                refetch();
            } else {
                handleErrors(response);
            }
        } catch (error) {
            toast.error(generateMessage(stateType, false));
        }
    };

    const handleDelete = async (id: string) => {
        const response = await (await service.deleteState(id)).getResponseData();
        if (response.success) {
            toast.success('Estado eliminado correctamente');
            refetch();
        } else {
            handleErrors(response);
        }
    };

    const handleMultiDelete = async (ids: string[]) => {
        const response = await (await service.deleteMultiStates(ids)).getResponseData();
        if (response.success) {
            toast.success('Estados eliminados correctamente');
            refetch();
        } else {
            handleErrors(response);
        }
    };

    /**
     * Order the data to match the column order
     */
    const orderedData = () => data?.data.map((row: any) => {
        const orderedRow: any = {};
        columnOrder.forEach((key) => {
            orderedRow[key] = row[key];
        });
        return orderedRow;
    });

    return (
        <>
            {data && data !== undefined && (
                <div key={data.totalRegisters}>
                    <CustomTable
                        largeTable
                        id={'states-table'}
                        columnOrder={columnOrder}
                        data={orderedData()}
                        isLoading={loading}
                        columnsNotShown={['entity', 'updatedAt', entity !== 'contract' ? 'clientInvoiceStatus' : '', entity !== 'contract' ? 'tutorInvoiceStatus' : '', entity !== 'contract' ? 'internalInvoiceStatus' : '']}
                        overrideColumns={[
                            {
                                key: 'statusOrder',
                                permissions: [{ group: 'statuses', permission: 'update_status_order' }],
                                render: (row: any) => {
                                    return (
                                        <OrderControl
                                            id={row.row.original.id}
                                            index={row.row.index}
                                            totalRegisters={data.totalRegisters}
                                            onOrderChange={updateStateOrder}
                                        />
                                    );
                                },
                            },
                            {
                                key: 'productType',
                                render: (row: any) => {
                                    return (row.row.original.productType?.name);
                                },
                            },
                            {
                                key: 'color',
                                render: (row: any) => {
                                    if (!row.row.original?.color) return null;
                                    return (
                                        <div
                                            key={row.row.original.id + row.row.original?.color}
                                            style={{
                                                backgroundColor: row.row.original?.color,
                                                border: "1px solid rgba(0, 0, 0, 0.1)",
                                                width: "35px",
                                                height: "35px",
                                            }}
                                            className={'rounded-full mr-3 p-3'}
                                        />
                                    );
                                },
                            },
                            {
                                key: 'draftStatus',
                                permissions: [{ group: 'statuses', permission: 'edit_statuses' }],
                                render: (row: any) => {
                                    return (
                                        <StatusToggle
                                            isActive={row.row.original.draftStatus}
                                            onToggle={() => toggleStatus(row.row.original.id, 'draft')}
                                            ActiveIcon={IoFileTrayFull}
                                            InactiveIcon={IoFileTrayFullOutline}
                                            tooltipMessage="Establecer como borrador"
                                        />
                                    );
                                }
                            },
                            {
                                key: 'initialStatus',
                                permissions: [{ group: 'statuses', permission: 'edit_statuses' }],
                                render: (row: any) => {
                                    return (
                                        <StatusToggle
                                            isActive={row.row.original.initialStatus}
                                            onToggle={() => toggleStatus(row.row.original.id, 'initial')}
                                            ActiveIcon={IoStar}
                                            InactiveIcon={IoMdStarOutline}
                                            tooltipMessage="Establecer como inicial"
                                        />
                                    );
                                }
                            },
                            {
                                key: 'satisfactoryStatus',
                                permissions: [{ group: 'statuses', permission: 'edit_statuses' }],
                                render: (row: any) => {
                                    return (
                                        <StatusToggle
                                            isActive={row.row.original.satisfactoryStatus}
                                            onToggle={() => toggleStatus(row.row.original.id, 'satisfactory')}
                                            ActiveIcon={FaThumbsUp}
                                            InactiveIcon={FaRegThumbsUp}
                                            tooltipMessage="Establecer como satisfactorio"
                                        />
                                    );
                                }
                            },
                            {
                                key: 'unsatisfactoryStatus',
                                permissions: [{ group: 'statuses', permission: 'edit_statuses' }],
                                render: (row: any) => {
                                    return (
                                        <StatusToggle
                                            isActive={row.row.original.unsatisfactoryStatus}
                                            onToggle={() => toggleStatus(row.row.original.id, 'unsatisfactory')}
                                            ActiveIcon={FaThumbsDown}
                                            InactiveIcon={FaRegThumbsDown}
                                            tooltipMessage="Establecer como insatisfactorio"
                                        />
                                    );
                                }
                            },
                            {
                                key: 'clientInvoiceStatus',
                                permissions: [{ group: 'statuses', permission: 'edit_statuses' }],
                                render: (row: any) => {
                                    return (
                                        <StatusToggle
                                            isActive={row.row.original.clientInvoiceStatus}
                                            onToggle={() => toggleStatus(row.row.original.id, 'clientInvoiceStatus')}
                                            ActiveIcon={FaFileInvoice}
                                            InactiveIcon={TbFileInvoice}
                                            tooltipMessage="Establecer como generador de factura de cliente"
                                        />
                                    );
                                }
                            },
                            {
                                key: 'tutorInvoiceStatus',
                                permissions: [{ group: 'statuses', permission: 'edit_statuses' }],
                                render: (row: any) => {
                                    return (
                                        <StatusToggle
                                            isActive={row.row.original.tutorInvoiceStatus}
                                            onToggle={() => toggleStatus(row.row.original.id, 'tutorInvoiceStatus')}
                                            ActiveIcon={FaFileInvoice}
                                            InactiveIcon={TbFileInvoice}
                                            tooltipMessage="Establecer como generador de factura de tutor"
                                        />
                                    );
                                }
                            },
                            {
                                key: 'internalInvoiceStatus',
                                permissions: [{ group: 'statuses', permission: 'edit_statuses' }],
                                render: (row: any) => {
                                    return (
                                        <StatusToggle
                                            isActive={row.row.original.internalInvoiceStatus}
                                            onToggle={() => toggleStatus(row.row.original.id, 'internalInvoiceStatus')}
                                            ActiveIcon={FaFileInvoice}
                                            InactiveIcon={TbFileInvoice}
                                            tooltipMessage="Establecer como generador de factura interna"
                                        />
                                    );
                                }
                            },
                            {
                                key: 'active',
                                render: (row: any) => {
                                    return (
                                        <Tooltip
                                            size="sm"
                                            content={row.row.original.active ? 'Desactivar estado' : 'Activar estado'}
                                            placement="top"
                                            color="invert"
                                        >
                                            <Switch
                                                id={row.row.original.id}
                                                checked={row.row.original.active}
                                                onChange={() => toggleState(row.row.original.id, row.row.original.active)}
                                                switchKnobClassName='bg-secondary'
                                            />
                                        </Tooltip>
                                    );
                                },
                            },
                        ]}
                        actions={[
                            {
                                label: 'Edit',
                                permissions: { group: 'statuses', permission: 'edit_statuses' },
                                render: (row: any) => {
                                    return (
                                        <Tooltip
                                            size="sm"
                                            content={'Editar estado'}
                                            placement="top"
                                            color="invert"
                                        >
                                            <Link to={`${menuRoutes.states.path}/${row.id}/edit`}>
                                                <ActionIcon
                                                    as="span"
                                                    size="sm"
                                                    variant="outline"
                                                    className="hover:!border-gray-900 hover:text-gray-700"
                                                >
                                                    <MdOutlineModeEditOutline className="h-4 w-4" size={20} />
                                                </ActionIcon>
                                            </Link>
                                        </Tooltip>
                                    );
                                },
                            },
                            {
                                label: 'Delete',
                                permissions: { group: 'statuses', permission: 'delete_statuses' },
                                render: (row: any) => {
                                    return (
                                        <Tooltip
                                            size="sm"
                                            content={'Eliminar estado'}
                                            placement="top"
                                            color="invert"
                                        >
                                            <div>
                                                <DeletePopover
                                                    title={`Eliminar estado`}
                                                    description={`¿Estás seguro de que deseas eliminar el estado ${row.name}?`}
                                                    onDelete={() => handleDelete(row.id)}
                                                />
                                            </div>
                                        </Tooltip>
                                    );
                                },
                            },
                        ]}
                        handleMultipleDelete={handleMultiDelete}
                        filters={filters}
                        updateFilters={updateFilters}
                        updateFilterOrder={updateFilterOrder}
                        defaultOrder={filters.filter_order || undefined}
                        paginationData={{
                            pageSize: filters.limit,
                            currentPage: filters.page,
                            pageCount: (data as StatesApiResponse) ? data.lastPage : 1,
                            totalCount: data?.totalRegisters,
                            handlePagination: updatePage,
                            handlePerPage: updatePageSize,
                        }}
                        toggleFilters={() => setOpenFilters(!openFilters)}
                    />
                </div>
            )}
        </>
    );
};

export default FilteredStatesTable;