import { useCallback, useState } from 'react';
import Page from '../../../layout/Page/Page';
import CustomTable from '../../../components/table/CustomTable';
import { useFiltersPR } from '../../../components/providers/FiltersProvider';
import useFetch from '../../../hooks/useFetch';
import { ActionIcon, Switch, Tooltip, Text } from 'rizzui';
import { Link, useNavigate } from 'react-router-dom';
import { MdOutlineHistoryEdu, MdOutlineLockReset, MdOutlineModeEditOutline } from "react-icons/md";
import { IoMdLogIn } from "react-icons/io";
import { HiOutlineEye } from "react-icons/hi2";
import DeletePopover from '../../../components/buttons/DeletePopover';
import { menuRoutes } from '../../../router/menu';
import { toast } from 'sonner';
import Spinner from '../../../components/bootstrap/Spinner';
import { UserService } from '../../../services/user/userService';
import { UsersApiResponse } from '../../../type/entities/user-type';
import UsersFilters from './UsersFilters';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../redux/store';
import { User, login } from '../../../redux/authSlice';
import { FilterDrawerView } from '../../../components/table/components/TableFilter';
import CustomPopover from '../../../components/buttons/CustomPopover';
import { formatDistance } from "date-fns";
import { es } from 'date-fns/locale';
import useHandleErrors from '../../../hooks/useHandleErrors';
import { usePrivilege } from '../../../components/priviledge/PriviledgeProvider';

const columnOrder = [
    'id',
    'profileImg',
    'name',
    'lastName',
    'email',
    'department',
    'commercialNetwork',
    'accessThrowCommercialNetworkIp',
    'userRoles',
    'active',
    'lastLogin',
    'createdAt',
    'updatedAt',
];

const UsersList = () => {

    const { user } = useSelector((state: RootState) => state.auth);
    const { userCan } = usePrivilege();
    const dispatch = useDispatch<AppDispatch>();
    const { handleErrors } = useHandleErrors();
    const navigate = useNavigate();
    const { filters, updateFilters, updateFilterOrder, updatePage, updatePageSize, resetFilters } = useFiltersPR();
    const service = new UserService();

    const [changingStatus, setChangingStatus] = useState<string[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [openFilters, setOpenFilters] = useState<boolean>(false);

    const [data, loading, error, refetch] = useFetch(useCallback(async () => {
        const response = await service.listUsers(filters);
        return response.getResponseData() as UsersApiResponse;
    }, [filters]));

    const toggleStatus = async (id: string, status: boolean) => {
        try {
            setChangingStatus([...changingStatus, id]);
            const response = (await service.toggleUserStatus(id, status)).getResponseData();
            if (response.success) {
                setChangingStatus(changingStatus.filter((item) => item !== id));
                refetch();
                toast.success(status ? "Se ha desactivado el usuario" : "Se ha activado el usuario");
            } else {
                setChangingStatus(changingStatus.filter((item) => item !== id));
                toast.error(response.message);
            }
        } catch (error: any) {
            setChangingStatus(changingStatus.filter((item) => item !== id));
            toast.error(error.message);
        }
    };

    const handleLoginAsUser = async (id: string) => {
        setIsLoading(true);
        const response = await service.loginAsUser(id) as any;
        const responseData = response.getResponseData();

        if (response && responseData?.success) {
            try {
                const user: User = {
                    id: responseData.data.user.id,
                    email: responseData.data.user.email,
                    token: responseData.data.token,
                    name: responseData.data.user.name,
                    lastName: responseData.data.user.lastName,
                    profileImage: responseData.data.user.imgProfile,
                    roles: responseData.data.user.roles,
                    commercialNetwork: responseData.data.user.commercialNetworks,
                    companyName: responseData.data.user.companyName,
                };
                dispatch(login({
                    isAuthenticated: true,
                    loading: false,
                    error: null,
                    user: user
                }))
                toast.success('Has accedido como ' + user.name);
            } catch (error) {
                toast.error('Error al intentar acceder como el usuario');
                return;
            } finally {
                setIsLoading(false);
            }
        }

        if (responseData.payload) {
            navigate('/users', { replace: true });
            setIsLoading(false);
        }
        ;

        if (!responseData?.success) {
            toast.error(response?.response?.message || 'Error al intentar acceder como el usuario');
            setIsLoading(false);
            dispatch(login({
                isAuthenticated: false,
                loading: false,
                error: response.message,
                user: null
            }));
            return;
        }
    };

    const handleDelete = async (id: string) => {
        try {
            const response = await (await service.deleteUser(id)).getResponseData();
            if (response.success) {
                refetch();
                toast.success('Usuario eliminado correctamente');
            } else {
                handleErrors(response);
            }
        } catch (error) {
            handleErrors(error);
        }
    };

    const handleMultiDelete = async (ids: string[]) => {
        try {
            const response = await (await service.deleteMultiUsers(ids)).getResponseData();
            if (response.success) {
                refetch();
                toast.success('Usuarios eliminados correctamente');
            } else {
                handleErrors(response);
            }
        } catch (error) {
            handleErrors(error);
        }
    };

    const resetUserPermissions = async (id: string) => {
        try {
            const response = await (await service.resetUserPermissions(id)).getResponseData();
            if (response.success) {
                refetch();
                toast.success('Permisos reseteados correctamente');
            } else {
                handleErrors(response);
            }
        } catch (error) {
            handleErrors(error);
        }
    };

    const capitalize = (str: string) => {
        if (typeof str !== 'string') return '';
        return str.charAt(0).toUpperCase() + str.slice(1);
    };

    /**
     * Order the data to match the column order
     */
    const orderedData = () => data?.data.map((row: any) => {
        const orderedRow: any = {};
        columnOrder.forEach((key) => {
            orderedRow[key] = row[key];
        });
        return orderedRow;
    });


    return (
        <Page container='fluid'>
            {data !== undefined && (
                <CustomTable
                    id={'users-table'}
                    columnOrder={columnOrder}
                    data={orderedData()}
                    isLoading={loading}
                    columnsNotShown={['lastName', 'department']}
                    overrideColumns={[
                        {
                            key: 'commercialNetwork',
                            render: (row: any) => {
                                return row.row.original.commercialNetwork ? row.row.original.commercialNetwork.name : 'N/A';
                            }
                        },
                        {
                            key: 'accessThrowCommercialNetworkIp',
                            render: (row: any) => {
                                return row.row.original.accessThrowCommercialNetworkIp ? 'Sí' : 'No';
                            }
                        },
                        {
                            key: 'userRoles',
                            render: (row: any) => {
                                return row.row.original.userRoles.map((role: any) => role.role.name).join(', ')
                            }
                        },
                        {
                            key: 'lastLogin',
                            render: (row: any) => {
                                const date = row.row.original?.lastLogin?.date;
                                return date ? capitalize(formatDistance(new Date(date), new Date(), { addSuffix: true, locale: es })) : 'Nunca';
                            },
                        },
                        {
                            key: 'active',
                            render: (row: any) => {
                                return (
                                    <Tooltip
                                        size="sm"
                                        content={row.row.original.active ? 'Desactivar usuario' : 'Activar usuario'}
                                        placement="top"
                                        color="invert"
                                    >
                                        <Switch
                                            id={row.row.original.id}
                                            checked={row.row.original.active}
                                            disabled={changingStatus.includes(row.row.original.id) || row.row.original.id === user?.id}
                                            onChange={() => toggleStatus(row.row.original.id, row.row.original.active)}
                                            switchKnobClassName='bg-secondary'
                                        />
                                    </Tooltip>
                                );
                            },
                        },
                    ]}
                    actions={[
                        {
                            label: 'Log in',
                            permissions: { group: 'user', permission: 'login_as_user' },
                            render: (row: any) => {
                                if (row.id === user?.id) return null;
                                return (
                                    <Tooltip
                                        size="sm"
                                        content={'Acceder como usuario'}
                                        placement="top"
                                        color="invert"
                                    >
                                        <ActionIcon
                                            as="span"
                                            size="sm"
                                            variant="outline"
                                            className="hover:!border-gray-900 hover:text-gray-700 cursor-pointer"
                                            onClick={() => handleLoginAsUser(row.id)}
                                            disabled={isLoading}
                                        >
                                            {isLoading ? <Spinner color={'dark'} /> :
                                                <IoMdLogIn className="h-4 w-4" size={20} />}
                                        </ActionIcon>
                                    </Tooltip>
                                );
                            }
                        },
                        {
                            label: 'View',
                            permissions: { group: 'user', permission: 'get_user' },
                            render: (row: any) => {
                                return (
                                    <Tooltip
                                        size="sm"
                                        content={'Ver perfil'}
                                        placement="top"
                                        color="invert"
                                    >
                                        <Link
                                            to={`${menuRoutes.users.path}/${row.id}/profile${menuRoutes.users.profile.info}`}>
                                            <ActionIcon
                                                as="span"
                                                size="sm"
                                                variant="outline"
                                                className="hover:!border-gray-900 hover:text-gray-700"
                                            >
                                                <HiOutlineEye className="h-4 w-4" size={20} />
                                            </ActionIcon>
                                        </Link>
                                    </Tooltip>
                                );
                            },
                        },
                        {
                            label: 'Ver Actividad',
                            permissions: { group: 'user', permission: 'user_profile_activity' },
                            render: (row: any) => {
                                return (
                                    <Tooltip
                                        size="sm"
                                        content={'Ver actividad'}
                                        placement="top"
                                        color="invert"
                                    >
                                        <ActionIcon
                                            as="span"
                                            size="sm"
                                            variant="outline"
                                            className="hover:!border-gray-900 hover:text-gray-700 cursor-pointer"
                                            onClick={() => navigate(`${menuRoutes.users.path}/${row.id}/profile/activity`)}
                                        >
                                            <MdOutlineHistoryEdu className="h-4 w-4" size={20} />
                                        </ActionIcon>

                                    </Tooltip>
                                );
                            },
                        },
                        {
                            label: 'Edit',
                            permissions: { group: 'user', permission: 'edit_user' },
                            render: (row: any) => {
                                return (
                                    <Tooltip
                                        size="sm"
                                        content={'Editar usuario'}
                                        placement="top"
                                        color="invert"
                                    >
                                        <Link to={`${menuRoutes.users.path}/${row.id}/edit/info`}>
                                            <ActionIcon
                                                as="span"
                                                size="sm"
                                                variant="outline"
                                                className="hover:!border-gray-900 hover:text-gray-700"
                                            >
                                                <MdOutlineModeEditOutline className="h-4 w-4" size={20} />
                                            </ActionIcon>
                                        </Link>
                                    </Tooltip>
                                );
                            },
                        },
                        {
                            label: 'Resetear Permisos',
                            permissions: { group: 'user', permission: 'edit_user_permissions' },
                            render: (row: any) => {
                                return (
                                    <Tooltip
                                        size="sm"
                                        content={'Resetear permisos'}
                                        placement="top"
                                        color="invert"
                                    >
                                        <div>
                                            <CustomPopover
                                                title={'Resetear permisos'}
                                                description={'¿Estás seguro de que deseas resetear los permisos de este usuario?'}
                                                onClick={() => resetUserPermissions(row.id)}
                                                icon={<MdOutlineLockReset className="h-4 w-4" size={20} />}
                                            />
                                        </div>
                                    </Tooltip>
                                );
                            },
                        },
                        {
                            label: 'Delete',
                            permissions: { group: 'user', permission: 'delete_user' },
                            render: (row: any) => {
                                if (row.id === user?.id) return null;
                                return (
                                    <Tooltip
                                        size="sm"
                                        content={'Eliminar'}
                                        placement="top"
                                        color="invert"
                                    >
                                        <div>
                                            <DeletePopover
                                                title={`Eliminar usuario`}
                                                description={`¿Estás seguro de que deseas eliminar a ${row.name}?`}
                                                onDelete={() => handleDelete(row.id)}
                                            />
                                        </div>
                                    </Tooltip>
                                );
                            },
                        },
                    ]}
                    handleMultipleDelete={handleMultiDelete}
                    filters={filters}
                    updateFilters={updateFilters}
                    updateFilterOrder={updateFilterOrder}
                    defaultOrder={filters.filter_order || undefined}
                    paginationData={{
                        pageSize: filters.limit,
                        currentPage: filters.page,
                        pageCount: (data as UsersApiResponse) ? data.lastPage : 1,
                        totalCount: data?.totalRegisters,
                        handlePagination: updatePage,
                        handlePerPage: updatePageSize,
                    }}
                    toggleFilters={() => setOpenFilters(!openFilters)}
                />
            )}

            <FilterDrawerView isOpen={openFilters} setOpenDrawer={setOpenFilters} drawerTitle={'Filtros Usuarios'}>
                <UsersFilters filters={filters} updateFilters={updateFilters} resetFilters={resetFilters} />
            </FilterDrawerView>
        </Page>
    );
};

export default UsersList;