import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import useFetch from '../../../../../hooks/useFetch';
import ClientProfileLayout from '../../ClientProfileLayout';
import { ClientService } from '../../../../../services/client/clientService';
import { ClientApiResponse } from '../../../../../type/entities/client-type';
import useFilters from '../../../../../hooks/useFilters';
import CustomTable from '../../../../../components/table/CustomTable';
import { ActionIcon, Button, Tooltip, cn } from 'rizzui';
import { MdOutlineModeEditOutline } from 'react-icons/md';
import DeletePopover from '../../../../../components/buttons/DeletePopover';
import { toast } from 'sonner';
import useHandleErrors from '../../../../../hooks/useHandleErrors';
import { StudentsApiResponse } from '../../../../../type/entities/student-type';
import { usePrivilege } from '../../../../../components/priviledge/PriviledgeProvider';
import { CommentModal } from './CommentModal';

interface ClientCommentsProps { }

const columnOrder = [
    'id',
    'comment',
    'user',
    'createdAt',
    'updatedAt',
];

const ClientComments: React.FC<ClientCommentsProps> = () => {

    const { id = '' } = useParams<{ id: string }>();
    const { handleErrors } = useHandleErrors();
    const { userCan } = usePrivilege();
    const service = new ClientService();

    const { filters, updateFilters, updateFilterOrder, updatePage, updatePageSize } = useFilters({ client: id });

    const [openFilters, setOpenFilters] = useState<boolean>(false);
    const [openCommentModal, setOpenCommentModal] = useState<boolean>(false);
    const [rowData, setRowData] = useState<any>({});

    const [data, loading, error, refetch] = useFetch(useCallback(async () => {
        if (!id || id === '') return null;
        const response = await service.listComments(filters);
        return response.getResponseData() as ClientApiResponse;
    }, [id, filters]));

    const handleDelete = async (id: string) => {
        const response = await (await service.deleteComment(id)).getResponseData();
        if (response.success) {
            toast.success('Comentario eliminado correctamente');
            refetch();
        } else {
            handleErrors(response);
        }
    };

    const handleMultiDelete = async (ids: string[]) => {
        const response = await (await service.deleteMultiComments(ids)).getResponseData();
        if (response.success) {
            toast.success('Comentarios eliminados correctamente');
            refetch();
        } else {
            handleErrors(response);
        }
    };

    /**
     * Order the data to match the column order
     */
    const orderedData = () => data?.data.map((row: any) => {
        const orderedRow: any = {};
        columnOrder.forEach((key) => {
            orderedRow[key] = row[key];
        });
        return orderedRow;
    });

    return (
        <ClientProfileLayout isLoading={loading}>
            {data !== undefined && (
                <div className="pt-4">
                    <CustomTable
                        id={'comments-table'}
                        columnOrder={columnOrder}
                        filter={false}
                        data={orderedData()}
                        isLoading={loading}
                        columnsNotShown={[]}
                        overrideColumns={[
                            {
                                key: 'user',
                                render: (row: any) => {
                                    return `${row.row.original.user.name} ${row.row.original.user.lastName || ''}`;
                                },
                            },
                        ]}
                        actions={[
                            {
                                label: 'Edit',
                                permissions: { group: 'clients', permission: 'edit_client_comments' },
                                render: (row: any) => {
                                    return (
                                        <Tooltip
                                            size="sm"
                                            content={'Editar comentario'}
                                            placement="top"
                                            color="invert"
                                        >
                                            <ActionIcon
                                                as="span"
                                                size="sm"
                                                variant="outline"
                                                className="hover:!border-gray-900 hover:text-gray-700 cursor-pointer"
                                                onClick={() => { setRowData(row); setOpenCommentModal(true) }}
                                            >
                                                <MdOutlineModeEditOutline className="h-4 w-4" size={20} />
                                            </ActionIcon>
                                        </Tooltip>
                                    );
                                },
                            },
                            {
                                label: 'Delete',
                                permissions: { group: 'clients', permission: 'delete_client_comments' },
                                render: (row: any) => {
                                    return (
                                        <Tooltip
                                            size="sm"
                                            content={'Eliminar'}
                                            placement="top"
                                            color="invert"
                                        >
                                            <div>
                                                <DeletePopover
                                                    title={`Eliminar comentario`}
                                                    description={`¿Estás seguro de que deseas eliminar el comentario?`}
                                                    onDelete={() => handleDelete(row.id)}
                                                />
                                            </div>
                                        </Tooltip>
                                    );
                                },
                            },
                        ]}
                        handleMultipleDelete={handleMultiDelete}
                        filters={filters}
                        updateFilters={updateFilters}
                        updateFilterOrder={updateFilterOrder}
                        defaultOrder={filters.filter_order || undefined}
                        paginationData={{
                            pageSize: filters.limit,
                            currentPage: filters.page,
                            pageCount: (data as StudentsApiResponse) ? data.lastPage : 1,
                            totalCount: data?.totalRegisters,
                            handlePagination: updatePage,
                            handlePerPage: updatePageSize,
                        }}
                        toggleFilters={() => setOpenFilters(!openFilters)}
                    >
                        {userCan('create_client_comments', 'clients') && (
                            <Button
                                color="primary"
                                onClick={() => { setRowData({}); setOpenCommentModal(true); }}
                                className={cn(['mt-4 md:mt-0'])}
                            >
                                Añadir comentario
                            </Button>
                        )}
                    </CustomTable>
                </div>
            )}

            <CommentModal data={rowData} isOpen={openCommentModal} onClose={() => setOpenCommentModal(false)} refetch={refetch} />
        </ClientProfileLayout>
    );
};

export default ClientComments;