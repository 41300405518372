import { useCallback, useState } from 'react';
import useHandleErrors from '../../../hooks/useHandleErrors';
import useFetch from '../../../hooks/useFetch';
import { toast } from 'sonner';
import Page from '../../../layout/Page/Page';
import CustomTable from '../../../components/table/CustomTable';
import { ActionIcon, Text, Tooltip } from 'rizzui';
import { Link } from 'react-router-dom';
import { menuRoutes } from '../../../router/menu';
import { MdOutlineModeEditOutline } from 'react-icons/md';
import DeletePopover from '../../../components/buttons/DeletePopover';
import { FilterDrawerView } from '../../../components/table/components/TableFilter';
import ContractsFilters from './ContractFilters';
import { ContractsApiResponse } from '../../../type/entities/contract-type';
import { ContractService } from '../../../services/contract/contractService';
import useContractStates from '../../../hooks/api-calls/contract/useContractStates';
import useContractProductsStates from '../../../hooks/api-calls/contract/useContractProductsStates';
import useContractInvoiceStates from '../../../hooks/api-calls/contract/useContractInvoicingStates';
import { usePrivilege } from '../../../components/priviledge/PriviledgeProvider';
import StatusDropdown from '../../../components/forms/StatusDropdown';
import { HiOutlineEye } from 'react-icons/hi2';
import { FaCommentAlt, FaPrint } from 'react-icons/fa';
import useHandleDownloadDocument from '../../../hooks/useDownloadDocument';
import { fixNumber } from '../../../utils/formatNumber';
import { useContractProvider } from '../providers/ContractProvider';
import { Loader } from '../../../components/loader/SpinnerLogo';
import { AddCommentModal } from '../../clients/list/components/AddCommentModal';

export const contractColumnOrder = [
    'id',
    'code',
    'currentContractStatus',
    'currentContractStatusDate',
    'currentProductStatus',
    'currentContractProductStatusDate',
    'currentInvoicingStatus',
    'currentInvoicingStatusDate',
    'currentIncidenceStatus',
    'currentContractIncidenceStatusDate',
    'productType',
    'product',
    'client',
    'clientCompanyName',
    'clientCifNif',
    'clientProvince',
    'totalAmount',
    'createdAt',
    'updatedAt',
    'invoiceId',
    'incidence',
    'complements',
    'isDraft',
];

const ContractsList = () => {

    const { userCan } = usePrivilege();
    const { handleDownloadDocumentNewTab } = useHandleDownloadDocument();
    const { handleErrors } = useHandleErrors();
    const { getContractStatesList, isLoadingContractStates } = useContractStates({ active: true, action: 'edit' });
    const { getContractProductsStatesList, isLoadingContractProductsStates } = useContractProductsStates({ active: true, action: 'edit' });
    const { getContractInvoiceStatesList, isLoadingContractInvoiceStates } = useContractInvoiceStates({ active: true, action: 'edit' });
    const { filters, updateFilters, updateFilterOrder, updatePage, updatePageSize, resetFilters } = useContractProvider();
    const service = new ContractService();

    const [openFilters, setOpenFilters] = useState<boolean>(false);
    const [openCommentModal, setOpenCommentModal] = useState<boolean>(false);
    const [rowId, setRowId] = useState<string>('');

    const [data, loading, error, refetch] = useFetch(useCallback(async () => {
        const response = await service.listContracts(filters);
        return response.getResponseData() as ContractsApiResponse;
    }, [filters]));

    const handleDelete = async (id: string) => {
        const response = await (await service.deleteContract(id)).getResponseData();
        if (response.success) {
            toast.success('Contrato eliminado correctamente');
            refetch();
        } else {
            handleErrors(response);
        }
    };

    const handleStateChange = async (id: string, stateId: string, comment: string, entityId: string, entityName: string) => {
        var response = { success: false };
        switch (entityName) {
            case 'contract':
                try {
                    response = await (await service.changeContractStatus(id, stateId, comment)).getResponseData();
                    if (response.success) {
                        toast.success('Estado del contrato actualizado correctamente');
                    } else {
                        handleErrors(response);
                    }
                } catch (error) {
                    toast.error('Error al actualizar el estado');
                }
                break;
            case 'invoice':
                try {
                    response = await (await service.changeContractInvoicingStatus(id, stateId, entityId, comment)).getResponseData();
                    if (response.success) {
                        toast.success('Estado de la factura actualizado correctamente');
                    } else {
                        handleErrors(response);
                    }
                } catch (error) {
                    toast.error('Error al actualizar el estado');
                }
                break;
            case 'product':
                try {
                    response = await (await service.changeContractProductStatus(id, stateId, entityId, comment)).getResponseData();
                    if (response.success) {
                        toast.success('Estado del producto actualizado correctamente');
                    } else {
                        handleErrors(response);
                    }
                } catch (error) {
                    toast.error('Error al actualizar el estado del producto');
                }
                break;
            case 'incidence':
                // response = await (await service.changeIncidenceState(id, stateId, entityId, comment)).getResponseData();
                break;
            default:
                break;
        }
        refetch();
    };

    const handleMultiDelete = async (ids: string[]) => {
        const response = await (await service.deleteMultiContracts(ids)).getResponseData();
        if (response.success) {
            toast.success('Contrato eliminados correctamente');
            refetch();
        } else {
            handleErrors(response);
        }
    };

    /**
     * Order the data to match the column order
     */
    const orderedData = () => data?.data.map((row: any) => {
        const orderedRow: any = {};
        contractColumnOrder.forEach((key) => {
            orderedRow[key] = row[key];
        });
        return orderedRow;
    });

    return (
        <Page container='fluid'>
            {!loading && !isLoadingContractStates && !isLoadingContractProductsStates && !isLoadingContractInvoiceStates && data !== undefined
                ? (
                    <CustomTable
                        largeTable
                        id={'contracts-table'}
                        columnOrder={contractColumnOrder}
                        data={orderedData()}
                        isLoading={loading}
                        columnsNotShown={['currentContractProductStatusDate', 'currentInvoicingStatusDate', 'clientCompanyName', 'clientCifNif', 'currentContractIncidenceStatusDate', 'currentContractStatusDate', 'currentIncidenceStatus', 'invoiceId', 'incidence', 'complements', 'isDraft']}
                        overrideColumns={[
                            {
                                key: 'code',
                                render: (row: any) => {
                                    return (
                                        userCan('get_contracts', 'contracts')
                                            ? (
                                                <Link to={`${menuRoutes.contracts.path}/${row.row.original.id}/profile${menuRoutes.contracts.profile.info}`}>
                                                    <Text className='font-bold text-primary'>{row.row.original.code}</Text>
                                                </Link>
                                            )
                                            : <Text className='font-bold text-primary'>{row.row.original.code}</Text>
                                    );
                                }
                            },
                            {
                                key: 'client',
                                render: (row: any) => {
                                    const client = row.row.original?.client;
                                    return (
                                        <>
                                            {client
                                                ? userCan('get_clients', 'clients')
                                                    ? (
                                                        <div>
                                                            <Link
                                                                to={`${menuRoutes.clients.path}/${client?.id}/profile/info`}
                                                                className='font-bold text-primary cursor-pointer d-block'
                                                            >
                                                                {row.row.original?.clientCompanyName || 'N/A'}
                                                            </Link>
                                                            {row.row.original?.clientCifNif && <div className='d-block'>{row.row.original?.clientCifNif}</div>}
                                                            {userCan('get_clients_comments', 'clients') && client.lastComment && (
                                                                <Tooltip
                                                                    size="sm"
                                                                    content={<div className='tooltip-container'>{client.lastComment}</div>}
                                                                    placement="top"
                                                                    color="invert"
                                                                >
                                                                    <ActionIcon
                                                                        as="span"
                                                                        size="sm"
                                                                        variant="text"
                                                                        className="hover:!border-gray-900 hover:text-gray-700"
                                                                    >
                                                                        <FaCommentAlt className="h-4 w-4" size={20} />
                                                                    </ActionIcon>
                                                                </Tooltip>
                                                            )}
                                                        </div>
                                                    )
                                                    : 'N/A'
                                                :
                                                (<span className='font-bold text-primary'>{row.row.original?.clientCompanyName || row.row.original?.clientCifNif || 'N/A'}</span>)
                                            }
                                        </>
                                    )
                                }
                            },
                            {
                                key: 'product',
                                render: (row: any) => {
                                    const product = row.row.original?.product;
                                    const complements = row.row.original?.complements;
                                    return (
                                        <div className='flex flex-col'>
                                            {product ?
                                                (<Link
                                                    to={`${menuRoutes.products.path}?filter_filters=%7B"active"%3Atrue%2C"search_text"%3A"${product.name}"%7D&filter_order=%5B%5D&page=1&limit=50`}
                                                    className='font-bold text-primary'
                                                >
                                                    {product.name}
                                                </Link>
                                                )
                                                : 'N/A'}

                                            {product && complements && complements.length >= 1 && (
                                                <div className='flex flex-col gap-1 mt-2 font-semibold'>
                                                    <Text className='font-semibold text-md'>Complementos:</Text>
                                                    <ul className='indent-4'>
                                                        {complements.map((complement: any, index: number) => (
                                                            <li
                                                                key={product.id + index}
                                                                className='text-md'
                                                            >
                                                                <Text className='text-xs text-nowrap'>- {complement.complements.name} ({complement.amount}€)</Text>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>)
                                            }


                                        </div>
                                    )
                                }
                            },
                            {
                                key: 'productType',
                                render: (row: any) => {
                                    return row.row.original?.productType?.name;
                                }
                            },
                            {
                                key: 'currentContractStatus',
                                render: (row: any) => {
                                    return (
                                        <div className='flex flex-col gap-2 justify-content-center text-center' key={row.row.original.id}>
                                            <StatusDropdown
                                                entityId={row.row.original?.id}
                                                title="¿Estas seguro de cambiar el estado del contrato?"
                                                statesOptions={getContractStatesList(null, row.row.original?.currentContractStatus?.id)}
                                                currentState={row.row.original?.currentContractStatus}
                                                currentStateDate={row.row.original?.currentContractStatusDate?.date}
                                                handleStateChange={(entityId: string, statusId: string, comment: string) =>
                                                    handleStateChange(entityId, statusId, comment, entityId, 'contract')
                                                }
                                            />
                                        </div>
                                    );
                                }
                            },
                            {
                                key: 'currentInvoicingStatus',
                                render: (row: any) => {
                                    const invoiceStatusDate = row.row.original?.currentInvoicingStatusDate?.date;
                                    const invoiceStatus = row.row.original?.currentInvoicingStatus;
                                    const invoiceId = row.row.original?.invoiceId;
                                    return (
                                        <div className='flex flex-col gap-2 justify-content-center text-center' key={row.row.original.id}>
                                            {(invoiceId && invoiceStatusDate && invoiceStatus) ? (
                                                <StatusDropdown
                                                    entityId={row.row.original?.id}
                                                    title="¿Estas seguro de cambiar el estado de la factura?"
                                                    statesOptions={getContractInvoiceStatesList(null, invoiceStatus.id)}
                                                    currentState={invoiceStatus}
                                                    currentStateDate={invoiceStatusDate}
                                                    handleStateChange={(entityId: string, statusId: string, comment: string) =>
                                                        handleStateChange(entityId, statusId, comment, invoiceId, 'invoice')
                                                    }
                                                />
                                            ) : "N/A"}
                                        </div>
                                    );
                                }
                            },
                            {
                                key: 'currentProductStatus',
                                render: (row: any) => {
                                    const productStatusDate = row.row.original?.currentContractProductStatusDate?.date;
                                    const productStatus = row.row.original?.currentProductStatus;
                                    const product = row.row.original?.product;
                                    return (
                                        <div className='flex flex-col gap-2 justify-content-center text-center' key={row.row.original.id}>
                                            {(productStatusDate && productStatus) ? (
                                                <StatusDropdown
                                                    entityId={row.row.original?.id}
                                                    title="¿Estas seguro de cambiar el estado del producto?"
                                                    statesOptions={getContractProductsStatesList(null, productStatus.id)}
                                                    currentState={productStatus}
                                                    currentStateDate={productStatusDate}
                                                    handleStateChange={(entityId: string, statusId: string, comment: string) =>
                                                        handleStateChange(entityId, statusId, comment, product.id, 'product')
                                                    }
                                                />
                                            ) : "N/A"}
                                        </div>
                                    );
                                }
                            },
                            // {
                            //     key: 'currentIncidenceStatus',
                            //     render: (row: any) => {
                            //         const incidenceStatusDate = row.row.original?.currentContractIncidenceStatusDate?.date;
                            //         const incidenceStatus = row.row.original?.currentIncidenceStatus;
                            //         const incidence = row.row.original?.incidence;
                            //         return (
                            //             <div className='flex flex-col gap-2 justify-content-center text-center'>
                            //                 {( incidence && incidenceStatusDate && incidenceStatus) ? (

                            //                         <StatusDropdown
                            //                             entityId={row.row.original?.id}
                            //                             title="¿Estas seguro de cambiar el estado de la incidencia?"
                            //                             statesOptions={[]} // TODO: Add incidence states api-call hook
                            //                             currentState={incidenceStatus}
                            //                             currentStateDate={incidenceStatusDate}
                            //                             handleStateChange={(entityId: string, statusId: string, comment: string) =>
                            //                                 handleStateChange(entityId, statusId, comment, incidence.id, 'product')
                            //                             }
                            //                         />
                            //                 ) : "N/A"}
                            //             </div>
                            //         );
                            //     }
                            // },
                            {
                                key: 'totalAmount',
                                render: (row: any) => {
                                    return (typeof row.row.original?.totalAmount === 'number' && !isNaN(row.row.original?.totalAmount)) ? `${fixNumber(row.row.original?.totalAmount)}€` : 'N/A';
                                }
                            },
                        ]}
                        actions={[
                            {
                                label: 'Generar Contrato',
                                permissions: { group: 'contracts', permission: 'print_contract' },
                                render: (row: any) => {
                                    if (row.isDraft) return null;
                                    return (
                                        <Tooltip
                                            size="sm"
                                            content={'Generar Contrato'}
                                            placement="top"
                                            color="invert"
                                        >
                                            <ActionIcon
                                                as="span"
                                                size="sm"
                                                variant="outline"
                                                className="hover:!border-gray-900 hover:text-gray-700 cursor-pointer"
                                                onClick={() => handleDownloadDocumentNewTab(service.generateContractPdf, row.id, row.code + '_contrato.pdf')}
                                            >
                                                <FaPrint className="h-4 w-4" size={20} />
                                            </ActionIcon>
                                        </Tooltip>
                                    );
                                },
                            },
                            {
                                label: 'Add Comment',
                                permissions: { group: 'contracts', permission: 'create_contract_comments' },
                                render: (row: any) => {
                                    return (
                                        <Tooltip
                                            size="sm"
                                            content={'Añadir comentario'}
                                            placement="top"
                                            color="invert"
                                        >
                                            <ActionIcon
                                                as="span"
                                                size="sm"
                                                variant="outline"
                                                className="hover:!border-gray-900 hover:text-gray-700 cursor-pointer"
                                                onClick={() => { setRowId(row.id); setOpenCommentModal(true) }}
                                            >
                                                <FaCommentAlt className="h-4 w-4" size={20} />
                                            </ActionIcon>
                                        </Tooltip>
                                    );
                                },
                            },
                            {
                                label: 'View',
                                permissions: { group: 'user', permission: 'get_user' },
                                render: (row: any) => {
                                    return (
                                        <Tooltip
                                            size="sm"
                                            content={'Ver detalles'}
                                            placement="top"
                                            color="invert"
                                        >
                                            <Link
                                                to={`${menuRoutes.contracts.path}/${row.id}/profile${menuRoutes.contracts.profile.info}`}>
                                                <ActionIcon
                                                    as="span"
                                                    size="sm"
                                                    variant="outline"
                                                    className="hover:!border-gray-900 hover:text-gray-700"
                                                >
                                                    <HiOutlineEye className="h-4 w-4" size={20} />
                                                </ActionIcon>
                                            </Link>
                                        </Tooltip>
                                    );
                                },
                            },
                            {
                                label: 'Edit',
                                permissions: { group: 'contracts', permission: 'edit_contracts' },
                                render: (row: any) => {
                                    return (
                                        <Tooltip
                                            size="sm"
                                            content={'Editar contrato'}
                                            placement="top"
                                            color="invert"
                                        >
                                            <Link to={`${menuRoutes.contracts.path}/${row.id}/edit`}>
                                                <ActionIcon
                                                    as="span"
                                                    size="sm"
                                                    variant="outline"
                                                    className="hover:!border-gray-900 hover:text-gray-700"
                                                >
                                                    <MdOutlineModeEditOutline className="h-4 w-4" size={20} />
                                                </ActionIcon>
                                            </Link>
                                        </Tooltip>
                                    );
                                },
                            },
                            {
                                label: 'Delete',
                                permissions: { group: 'contracts', permission: 'delete_contracts' },
                                render: (row: any) => {
                                    return (
                                        <Tooltip
                                            size="sm"
                                            content={'Eliminar'}
                                            placement="top"
                                            color="invert"
                                        >
                                            <div>
                                                <DeletePopover
                                                    title={`Eliminar contrato`}
                                                    description={`¿Estás seguro de que deseas eliminar el contrato ${row.code}?`}
                                                    onDelete={() => handleDelete(row.id)}
                                                />
                                            </div>
                                        </Tooltip>
                                    );
                                },
                            },
                        ]}
                        handleMultipleDelete={handleMultiDelete}
                        filters={filters}
                        updateFilters={updateFilters}
                        updateFilterOrder={updateFilterOrder}
                        defaultOrder={filters.filter_order || undefined}
                        paginationData={{
                            pageSize: filters.limit,
                            currentPage: filters.page,
                            pageCount: (data as ContractsApiResponse) ? data.lastPage : 1,
                            totalCount: data?.totalRegisters,
                            handlePagination: updatePage,
                            handlePerPage: updatePageSize,
                        }}
                        toggleFilters={() => setOpenFilters(!openFilters)}
                    />
                )
                : (<Loader height="70vh" />)
            }

            <AddCommentModal service={(new ContractService).addComment} id={rowId} isOpen={openCommentModal} onClose={() => setOpenCommentModal(false)} refetch={refetch} />

            <FilterDrawerView isOpen={openFilters} setOpenDrawer={setOpenFilters} drawerTitle={'Filtros Contratos'}>
                <ContractsFilters filters={filters} updateFilters={updateFilters} resetFilters={resetFilters} />
            </FilterDrawerView>
        </Page>
    );
};

export default ContractsList;