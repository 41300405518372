import React, { useCallback, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import useFetch from '../../../../../hooks/useFetch';
import { InvoicesApiResponse } from '../../../../../type/entities/invoice-type';
import { InvoiceService } from '../../../../../services/invoice/invoiceService';
import useFilters from '../../../../../hooks/useFilters';
import CustomTable from '../../../../../components/table/CustomTable';
import moment from 'moment';
import { ActionIcon, Tooltip } from 'rizzui';
import { CustomBadge } from '../../../../../components/forms/CustomBadge';
import { menuRoutes } from '../../../../../router/menu';
import { MdOutlineModeEditOutline } from 'react-icons/md';
import DeletePopover from '../../../../../components/buttons/DeletePopover';
import { FilterDrawerView } from '../../../../../components/table/components/TableFilter';
import InvoicesFilters from '../../../../invoicing/list/InvoicesFilters';
import { toast } from 'sonner';
import useHandleErrors from '../../../../../hooks/useHandleErrors';
import { FaPrint } from 'react-icons/fa';
import useHandleDownloadDocument from '../../../../../hooks/useDownloadDocument';
import TutorProfileLayout from '../../TutorProfileLayout';
import { fixNumber } from '../../../../../utils/formatNumber';

interface TutorInvoicesProps { }

const columnOrder = [
    'id',
    'number',
    'totalPrice',
    'taxableAmount',
    'tax',
    'totalTaxesAmount',
    'totalProducts',
    'currentStatus',
    'corporation',
    'invoiceDate',
    'paymentDate',
    'expirationDate',
    'createdAt',
    'updatedAt',
    'invoiceDates',
];

const TutorInvoices: React.FC<TutorInvoicesProps> = ({ }) => {

    const { id = '' } = useParams<{ id: string }>();
    const { filters, updateFilters, updateFilterOrder, updatePage, updatePageSize, resetFilters } = useFilters({ tutor: [id] });
    const { handleErrors } = useHandleErrors();
    const { handleDownloadDocumentNewTab } = useHandleDownloadDocument();
    const service = new InvoiceService();

    const [openFilters, setOpenFilters] = useState<boolean>(false);

    const [data, loading, error, refetch] = useFetch(useCallback(async () => {
        const response = await service.listInvoices(filters);
        return response.getResponseData() as InvoicesApiResponse;
    }, [filters]));

    const handleDelete = async (id: string) => {
        const response = await (await service.deleteInvoice(id)).getResponseData();
        if (response.success) {
            toast.success('Factura eliminada correctamente');
            refetch();
        } else {
            handleErrors(response);
        }
    };

    const handleMultiDelete = async (ids: string[]) => {
        const response = await (await service.deleteMultiInvoices(ids)).getResponseData();
        if (response.success) {
            toast.success('Facturas eliminadas correctamente');
            refetch();
        } else {
            handleErrors(response);
        }
    };

    /**
     * Order the data to match the column order
     */
    const orderedData = data?.data.map((row: any) => {
        const orderedRow: any = {};
        columnOrder.forEach((key) => {
            orderedRow[key] = row[key];
        });
        return orderedRow;
    });

    return (
        <TutorProfileLayout isLoading={loading}>
            <div className="pt-4">
                <CustomTable
                    id={'invoices-table'}
                    data={orderedData}
                    columnOrder={columnOrder}
                    isLoading={loading}
                    columnsNotShown={['company', 'lines', 'invoiceDate', 'paymentDate', 'expirationDate', 'invoiceDates']}
                    additionalColumns={[
                        {
                            id: 'invoiceDates',
                            header: 'Fechas Factura',
                            render: (row: any) => {
                                const invoiceDate = row.row.original?.invoiceDate.date;
                                const paymentDate = row.row.original?.paymentDate.date;
                                const expirationDate = row.row.original?.expirationDate.date;
                                return (
                                    <ul className='gap-x-4'>
                                        <li className='flex flex-nowrap pb-1'>
                                            <h6 className='text-nowrap text-gray-400'>Factura:&nbsp;</h6>
                                            <span className='text-nowrap'>{invoiceDate ? moment(invoiceDate).format('DD/MM/YY H:mm') : 'N/A'}</span>
                                        </li>
                                        <li className='flex flex-nowrap pb-1'>
                                            <h6 className='text-nowrap text-gray-400'>Pago:&nbsp;</h6>
                                            <span className='text-nowrap'>{paymentDate ? moment(paymentDate).format('DD/MM/YY H:mm') : 'N/A'}</span>
                                        </li>
                                        <li className='flex flex-nowrap pb-1'>
                                            <h6 className='text-nowrap text-gray-400'>Vencimiento:&nbsp;</h6>
                                            <span className='text-nowrap'>{expirationDate ? moment(expirationDate).format('DD/MM/YY H:mm') : 'N/A'}</span>
                                        </li>
                                    </ul>
                                );
                            }
                        },
                    ]}
                    overrideColumns={[
                        {
                            key: 'number',
                            columnNameKey: 'invoiceNumber',
                            render: (row: any) => {
                                return row.row.original?.number || 'N/A';
                            },
                        },
                        {
                            key: 'totalPrice',
                            render: (row: any) => {
                                return (
                                    <div className="text-center">
                                        {row.row.original?.totalPrice ? `${fixNumber(row.row.original?.totalPrice)}€` : 'N/A'}
                                    </div>
                                )
                            },
                        },
                        {
                            key: 'taxableAmount',
                            render: (row: any) => {
                                return (
                                    <div className="text-center">
                                        {row.row.original?.taxableAmount ? `${fixNumber(row.row.original?.taxableAmount)}€` : 'N/A'}
                                    </div>
                                )
                            },
                        },
                        {
                            key: 'currentStatus',
                            render: (row: any) => {
                                return (
                                    <Tooltip
                                        size="sm"
                                        content={row.row.original?.currentStatus?.description}
                                        placement="top"
                                        color="invert"
                                    >
                                        <CustomBadge customColor={row.row.original?.currentStatus?.color} rounded='md'>
                                            {row.row.original?.currentStatus?.name}
                                        </CustomBadge>
                                    </Tooltip>
                                );
                            }
                        },
                        {
                            key: 'corporation',
                            render: (row: any) => {
                                return row.row.original?.corporation?.name || 'N/A';
                            },
                        },
                        {
                            key: 'tax',
                            render: (row: any) => {
                                return (
                                    row.row.original?.tax ? `${row.row.original?.tax}%` : 'N/A'
                                );
                            },
                        },
                        {
                            key: 'totalTaxesAmount',
                            render: (row: any) => {
                                return (
                                    <div className="text-center">
                                        {row.row.original?.totalTaxesAmount ? `${fixNumber(row.row.original?.totalTaxesAmount)}€` : 'N/A'}
                                    </div>
                                )

                            },
                        },
                        {
                            key: 'totalProducts',
                            render: (row: any) => {
                                return (
                                    <div className="text-center">
                                        {row.row.original?.totalProducts ? `${row.row.original?.totalProducts}` : 'N/A'}
                                    </div>
                                )
                            },
                        },
                    ]}
                    actions={[
                        {
                            label: 'Print PDF',
                            permissions: { group: 'invoices', permission: 'print_invoices' },
                            render: (row: any) => {
                                return (
                                    <Tooltip
                                        size="sm"
                                        content={'Imprimir PDF'}
                                        placement="top"
                                        color="invert"
                                    >
                                        <ActionIcon
                                            as="span"
                                            size="sm"
                                            variant="outline"
                                            className="hover:!border-gray-900 hover:text-gray-700 cursor-pointer"
                                            onClick={() => handleDownloadDocumentNewTab((new InvoiceService).printInvoice, row.id, moment(row.invoiceDate.date).format('DD-MM-YYYY') + '_factura.pdf')}
                                        >
                                            <FaPrint className="h-4 w-4" size={20} />
                                        </ActionIcon>
                                    </Tooltip>
                                );
                            },
                        },
                        {
                            label: 'Edit',
                            permissions: { group: 'invoices', permission: 'edit_invoices' },
                            render: (row: any) => {
                                return (
                                    <Tooltip
                                        size="sm"
                                        content={'Editar factura'}
                                        placement="top"
                                        color="invert"
                                    >
                                        <Link to={`${menuRoutes.invoices.path}/${row.id}/edit`}>
                                            <ActionIcon
                                                as="span"
                                                size="sm"
                                                variant="outline"
                                                className="hover:!border-gray-900 hover:text-gray-700"
                                            >
                                                <MdOutlineModeEditOutline className="h-4 w-4" size={20} />
                                            </ActionIcon>
                                        </Link>
                                    </Tooltip>
                                );
                            },
                        },
                        {
                            label: 'Delete',
                            permissions: { group: 'invoices', permission: 'delete_invoices' },
                            render: (row: any) => {
                                return (
                                    <Tooltip
                                        size="sm"
                                        content={'Eliminar'}
                                        placement="top"
                                        color="invert"
                                    >
                                        <div>
                                            <DeletePopover
                                                title={`Eliminar factura`}
                                                description={`¿Estás seguro de que deseas eliminar esta factura?`}
                                                onDelete={() => handleDelete(row.id)}
                                            />
                                        </div>
                                    </Tooltip>
                                );
                            },
                        },
                    ]}
                    handleMultipleDelete={handleMultiDelete}
                    filters={filters}
                    updateFilters={updateFilters}
                    updateFilterOrder={updateFilterOrder}
                    defaultOrder={filters.filter_order || undefined}
                    paginationData={{
                        pageSize: filters.limit,
                        currentPage: filters.page,
                        pageCount: (data as InvoicesApiResponse) ? data.lastPage : 1,
                        totalCount: data?.totalRegisters,
                        handlePagination: updatePage,
                        handlePerPage: updatePageSize,
                    }}
                    toggleFilters={() => setOpenFilters(!openFilters)}
                />
            </div>
            <FilterDrawerView isOpen={openFilters} setOpenDrawer={setOpenFilters} drawerTitle={'Filtros Facturas'}>
                <InvoicesFilters filters={filters} updateFilters={updateFilters} resetFilters={resetFilters} />
            </FilterDrawerView>
        </TutorProfileLayout>
    );
};

export default TutorInvoices;