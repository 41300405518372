export const revenueSummaryData2 = [
    {
        day: 'Ene',
        revenue: 500,
    },
    {
        day: 'Feb',
        revenue: 850,
    },
    {
        day: 'Mar',
        revenue: 700,
    },
    {
        day: 'Abr',
        revenue: 390,
    },
    {
        day: 'May',
        revenue: 489,
    },
    {
        day: 'Jun',
        revenue: 800,
    },
    {
        day: 'Jul',
        revenue: 850,
    },
    {
        day: 'Ago',
        revenue: 700,
    },
    {
        day: 'Sep',
        revenue: 398,
    },
    {
        day: 'Oct',
        revenue: 489,
    },
    {
        day: 'Nov',
        revenue: 800,
    },
    {
        day: 'Dic',
        revenue: 850,
    },
];