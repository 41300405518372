import React, { useCallback, useEffect } from 'react';
import { Button, Tooltip } from 'rizzui';
import { usePrivilege } from '../../../components/priviledge/PriviledgeProvider';
import TabNav, { MenuItem } from "../../../components/navigation/TabNav";
import { menuRoutes } from "../../../router/menu";
import PageHeader from "../../../layout/shared/page-header";
import { useNavigate, useParams } from "react-router-dom";
import DeletePopover from '../../../components/buttons/DeletePopover';
import useFetch from '../../../hooks/useFetch';
import { UserApiResponse } from '../../../type/entities/user-type';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { setSelectedView } from '../../../redux/contractProfileSlice';
import { ContractService } from '../../../services/contract/contractService';
import { FaPrint } from 'react-icons/fa6';
import moment from 'moment';
import useHandleDownloadDocument from '../../../hooks/useDownloadDocument';
import { Loader } from '../../../components/loader/SpinnerLogo';
import { toast } from 'sonner';
import useHandleErrors from '../../../hooks/useHandleErrors';

const menuItems: MenuItem[] = [
    {
        label: 'Información',
        path: menuRoutes.contracts.profile.info,
        permission: {
            group: 'contracts',
            action: 'get_contracts',
        },
    },
    {
        label: 'Facturación',
        path: menuRoutes.contracts.profile.invoicing,
        permission: {
            group: 'contracts',
            action: 'get_contract_invoicing',
        },
    },
    {
        label: 'Histórico de estados',
        path: menuRoutes.contracts.profile.statesHistory,
        permission: {
            group: 'contracts',
            action: 'show_contract_status_history',
        },
    },
    {
        label: 'Documentos',
        path: menuRoutes.contracts.profile.documents,
        permission: {
            group: 'contracts',
            action: 'list_contracts',
        },
    },
    {
        label: 'Actividad',
        path: menuRoutes.contracts.profile.changelogs,
        permission: {
            group: 'contracts',
            action: 'contract_changes_log',
        },
    },
    {
        label: 'Comentarios',
        path: menuRoutes.contracts.profile.comments,
        permission: {
            group: 'contracts',
            action: 'get_contract_comments',
        },
    }

];

export default function ContractProfileLayout({ children, isLoading }: { children: React.ReactNode, isLoading?: boolean }) {

    const { selectedView } = useSelector((state: RootState) => state.contractProfile);
    const { id = '' } = useParams<{ id: string }>();
    const { userCan } = usePrivilege();
    const { handleErrors } = useHandleErrors();
    const { handleDownloadDocumentNewTab } = useHandleDownloadDocument();
    const navigate = useNavigate();

    const [data] = useFetch(useCallback(async () => {
        if (!id || id === '') return null;
        const response = await (new ContractService()).getContractById(id);
        return response.getResponseData() as UserApiResponse;
    }, [id]));

    const pageHeader = {
        title: `Datos del Contrato ${data?.code ? `(${data.code})` : ''}`,
        breadcrumb: [
            { name: 'Ventas' },
            { name: 'Gestión de contratos' },
            { name: 'Contratos', href: menuRoutes.contracts.path },
            { name: 'Detalles del Contrato' },
        ],
    };

    const handleDelete = async (id: string) => {
        try {
            const response = await (await (new ContractService()).deleteContract(id)).getResponseData();
            if (response.success) {
                navigate(menuRoutes.contracts.path);
                toast.success('Contrato eliminado correctamente');
            } else {
                handleErrors(response);
            }
        } catch (error) {
            handleErrors(error);
        }
    };

    useEffect(() => {
        navigate(`${menuRoutes.contracts.path}/${id}/profile${selectedView}`);
    }, [selectedView]);

    return (
        <>
            <PageHeader title={pageHeader.title} breadcrumb={pageHeader.breadcrumb}>
                <div className='flex flex-row flex-nowrap gap-3 mt-3 md:mt-0'>
                    {userCan('print_contract', 'contracts') && data?.isDraft === false && (
                        <Tooltip content={'Generar PDF'} placement={'top'} color={'invert'} size={'md'}>
                            <Button
                                variant={'outline'}
                                color={'secondary'}
                                size={'md'}
                                onClick={() => handleDownloadDocumentNewTab((new ContractService()).generateContractPdf, data?.id, moment().format('DD-MM-YYYY') + '_contrato.pdf')}
                            >
                                <FaPrint className="h-4 w-4" size={20} />
                            </Button>
                        </Tooltip>
                    )}

                    {userCan('edit_contracts', 'contracts') && (
                        <Tooltip content={'Editar contrato'} placement={'top'} color={'invert'} size={'md'}>
                            <Button variant={'outline'} color={'primary'} size={'md'} onClick={() => {
                                navigate(`${menuRoutes.contracts.path}/${id}/edit`)
                            }}>
                                Editar
                            </Button>
                        </Tooltip>
                    )}

                    {userCan('delete_contracts', 'contracts') && (
                        <Tooltip content={'Eliminar contrato'} placement={'top'} color={'invert'} size={'md'} className='text-danger'>
                            <div>
                                <DeletePopover
                                    title={`Eliminar contrato`}
                                    description={`¿Estás seguro de que deseas eliminar el contrato?`}
                                    size={'md'}
                                    onDelete={() => handleDelete(id)}
                                    actionIconClassName='h-auto w-10 h-10'
                                />
                            </div>
                        </Tooltip>
                    )}
                </div>
            </PageHeader>

            <TabNav menuItems={menuItems} setSelectedView={setSelectedView} />

            {isLoading ? <Loader height='60vh' /> : children}
        </>
    );
}