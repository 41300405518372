import { PiFolder, PiGridFourDuotone, PiNewspaperClippingDuotone } from "react-icons/pi";
import { HeaderMenuItem } from "../type/menu-type";
import { profile } from "console";

export const menuRoutes = {
    auth: {
        login: "/login",
        forgot_password: "/forgot-password",
        reset_password: "/reset-password",
    },
    dashboard: {
        path: "/dashboard",
    },
    organisations: {
        path: "/organisations",
        create: "create",
        edit: ":id/edit",
    },
    roles: {
        path: "/roles",
        create: "create",
        edit: ":id/edit",
    },
    users: {
        path: "/users",
        create: "create",
        edit: ":id/edit/info",
        edit_permissions: ":id/edit/permissions",
        profile: {
            path: ":id/profile",
            info: "/info",
            stats: "/stats",
            documents: "/documents",
            permissions: "/permissions",
            contract_history: "/contract-history",
            activity: "/activity",
        },
    },
    products: {
        path: "/products",
        create: "create",
        edit: ":id/edit",
    },
    states: {
        path: "/states",
        create: ":entity/create",
        edit: ":id/edit",
        list: {
            contract: "/contract_states",
            contract_product: "/contract_product_states",
            contract_invoicing: "/contract_invoicing_states",
            contract_incidence: "/contract_incidence_states",
            expiration: "/expiration_states",
            invoice: "/invoice_states",
            incidence: "/incidence_states",
            renovation: "/renovation_states",
        }
    },
    corporations: {
        path: "/corporations",
        create: "create",
        edit: ":id/edit",
    },
    tutors: {
        path: "/tutors",
        create: "create",
        edit: ":id/edit",
        profile: {
            path: ":id/profile",
            info: "/info",
            stats: "/stats",
            documents: "/documents",
            invoices: "/invoices",
            contracts: "/contracts",
        },
    },
    invoices: {
        path: "/invoices",
        create: "create",
        edit: ":id/edit",
        profile: {
            path: ":id/profile",
            info: "/info",
            activityHistory: "/activity-history",
        },
    },
    tutor_panel: {
        tutorials: "/my-tutorials",
        invoices: "/my-invoices",
        calendar: "/my-calendar",
        documents: "/my-documents",
        profile: "/my-profile",
        login: "/tutor-login",
        forgot_password: "/tutor-forgot-password",
        reset_password: "/tutor-reset-password",
    },
    networks: {
        path: "/networks",
        create: "create",
        edit: ":id/edit",
    },
    departments: {
        path: "/departments",
        create: "create",
        edit: ":id/edit",
    },
    clients: {
        path: "/clients",
        create: "create",
        edit: ":id/edit",
        profile: {
            path: ":id/profile",
            info: "/info",
            students: "/students",
            products: "/products",
            contracts: "/contracts",
            consultancy: "/consultancy",
            invoices: "/invoices",
            calls: "/calls",
            comments: "/comments",
            creditHistory: "/credit-history",
            activityHistory: "/activity-history",
        },
    },
    students: {
        path: "/students",
        create: "create",
        edit: ":id/edit",
        profile: {
            path: ":id/profile",
            info: "/info",
            activityHistory: "/activity-history",
        },
    },
    consultancies: {
        path: "/consultancies",
        create: "create",
        edit: ":id/edit",
    },
    generalConfiguration: {
        path: "/general-configuration",
        professionalCategories: "/professional_categories",
        quotationGroups: "/quotation_groups",
        educationalLevels: "/studies",
        campaigns: "/campaigns",
    },
    contracts: {
        path: "/contracts",
        create: "create",
        edit: ":id/edit",
        profile: {
            path: ":id/profile",
            info: "/info",
            invoicing: "/invoicing",
            documents: "/documents",
            statesHistory: "/states-history",
            changelogs: "/changelogs",
            comments: "/comments",
        }
    },
    settings: {
        path: "/settings",
        invoices: "/invoices",
        contracts: "/contracts",
        holidays: "/holidays",
    }
};

export const attivaMenuItems: HeaderMenuItem = {
    dashboardMenu: {
        id: "dashboard",
        name: "Inicio",
        path: "/dashboard",
        type: "enhance",
        permissions_required: [{
            group: "dashboard",
            action: "get_dashboard_information",
        }],
    },
    contractsMenu: {
        id: "contracts",
        name: "Contratos",
        path: menuRoutes.contracts.path,
        icon: "DocumentIcon",
        react_icon: PiFolder,
        type: "link",
        permissions_required: [
            {
                group: "contracts",
                action: "admin_contracts",
            }
        ],
    },
    salesMenu: {
        id: "sales",
        name: "Ventas",
        path: "/sales",
        react_icon: PiFolder,
        type: "enhance",
        dropdownItems: [
            {
                id: "clients",
                name: "Gestión de clientes",
                path: "",
                icon: "UserLockIcon",
                subMenuItems: [
                    {
                        name: "Clientes",
                        href: menuRoutes.clients.path,
                        permissions_required: [
                            {
                                group: "clients",
                                action: "list_clients",
                            }
                        ],
                    },
                    {
                        name: "Empleados",
                        href: menuRoutes.students.path,
                        permissions_required: [
                            {
                                group: "students",
                                action: "list_students",
                            }
                        ],
                    },
                    {
                        name: "Asesorías",
                        href: menuRoutes.consultancies.path,
                        permissions_required: [
                            {
                                group: "consultancies",
                                action: "list_consultancies",
                            }
                        ],
                    },
                ]
            },
            {
                id: "contracts",
                name: "Gestión de contratos",
                icon: "DocumentIcon",
                subMenuItems: [
                    {
                        name: 'Contratos',
                        href: menuRoutes.contracts.path,
                        permissions_required: [{
                            group: 'contracts',
                            action: 'list_contracts',
                        }],
                    },
                    {
                        name: "Facturación",
                        href: menuRoutes.invoices.path,
                        icon: "PointOfSellIcon",
                        permissions_required: [
                            {
                                group: "invoices",
                                action: "list_invoices",
                            }
                        ],
                    },
                ]
            },
        ]
    },
    administrationMenu: {
        id: "administration",
        name: "Administración",
        path: "/admin",
        react_icon: PiFolder,
        type: "enhance",
        dropdownItems: [
            {
                id: "access-and-credentials",
                name: "Control de acceso",
                path: "",
                icon: "UserLockIcon",
                subMenuItems: [
                    {
                        name: "Usuarios",
                        href: menuRoutes.users.path,
                        icon: "FolderIcon",
                        permissions_required: [
                            {
                                group: "user",
                                action: "list_user",
                            }
                        ],
                    },
                    {
                        name: "Tutores",
                        href: menuRoutes.tutors.path,
                        icon: "FolderIcon",
                        permissions_required: [
                            {
                                group: "tutors",
                                action: "admin_tutors",
                            }
                        ],
                    },
                    {
                        name: "Roles",
                        href: menuRoutes.roles.path,
                        icon: "RolesIcon",
                        permissions_required: [
                            {
                                group: "roles",
                                action: "list_roles",
                            },
                        ],
                    },
                ]
            },
            {
                id: "states",
                name: "Gestión de estados",
                icon: "DocumentIcon",
                subMenuItems: [
                    {
                        name: 'Contrato',
                        href: menuRoutes.states.path + menuRoutes.states.list.contract,
                        permissions_required: [{
                            group: 'statuses',
                            action: 'admin_statuses',
                        }],
                    },
                    {
                        name: 'Producto del contr.',
                        href: menuRoutes.states.path + menuRoutes.states.list.contract_product,
                        permissions_required: [{
                            group: 'statuses',
                            action: 'admin_statuses',
                        }],
                    },
                    {
                        name: 'Facturación del contr.',
                        href: menuRoutes.states.path + menuRoutes.states.list.contract_invoicing,
                        permissions_required: [{
                            group: 'statuses',
                            action: 'admin_statuses',
                        }],
                    },
                    {
                        name: 'Incidencia del contr.',
                        href: menuRoutes.states.path + menuRoutes.states.list.contract_incidence,
                        permissions_required: [{
                            group: 'statuses',
                            action: 'admin_statuses',
                        }],
                    },
                    {
                        name: 'Vencimiento',
                        href: menuRoutes.states.path + menuRoutes.states.list.expiration,
                        permissions_required: [{
                            group: 'statuses',
                            action: 'admin_statuses',
                        }],
                    },
                    {
                        name: 'Facturas',
                        href: menuRoutes.states.path + menuRoutes.states.list.invoice,
                        permissions_required: [{
                            group: 'statuses',
                            action: 'admin_statuses',
                        }],
                    },

                    {
                        name: 'Incidencia',
                        href: menuRoutes.states.path + menuRoutes.states.list.incidence,
                        permissions_required: [{
                            group: 'statuses',
                            action: 'admin_statuses',
                        }],
                    },
                    {
                        name: 'Renovación',
                        href: menuRoutes.states.path + menuRoutes.states.list.renovation,
                        permissions_required: [{
                            group: 'statuses',
                            action: 'admin_statuses',
                        }],
                    },
                ]
            },
            {
                id: "products",
                name: "Gestión productos",
                icon: "PackageOpenIcon",
                subMenuItems: [
                    {
                        name: "Productos",
                        href: menuRoutes.products.path,
                        icon: "FolderIcon",
                        permissions_required: [
                            {
                                group: "products",
                                action: "list_products",
                            }
                        ],
                    },
                ]
            },
            {
                id: "networks",
                name: "Estructura",
                path: "",
                icon: "StructureIcon",
                subMenuItems: [
                    {
                        name: "Empresas",
                        href: menuRoutes.corporations.path,
                        icon: "ApartmentIcon",
                        permissions_required: [
                            {
                                group: "corporation",
                                action: "admin_corporation",
                            }
                        ],
                    },
                    {
                        name: "Redes y deptos.",
                        href: menuRoutes.networks.path,
                        icon: "NetworksIcon",
                        permissions_required: [
                            {
                                group: "commercial_network",
                                action: "admin_commercial_network",
                            },
                            {
                                group: "departments",
                                action: "admin_departments",
                            }
                        ],
                    },
                ]
            },
            {
                id: "generalConfiguration",
                name: "Configuración",
                path: "",
                icon: "ConfigIcon",
                subMenuItems: [
                    {
                        name: "Cat. profesionales",
                        href: menuRoutes.generalConfiguration.path + menuRoutes.generalConfiguration.professionalCategories,
                        permissions_required: [
                            {
                                group: "professional_categories",
                                action: "list_professional_categories",
                            }
                        ],
                    },
                    {
                        name: "Grupos de cotización",
                        href: menuRoutes.generalConfiguration.path + menuRoutes.generalConfiguration.quotationGroups,
                        permissions_required: [
                            {
                                group: "quotation_groups",
                                action: "list_quotation_groups",
                            }
                        ],
                    },
                    {
                        name: "Niveles educativos",
                        href: menuRoutes.generalConfiguration.path + menuRoutes.generalConfiguration.educationalLevels,
                        permissions_required: [
                            {
                                group: "studies",
                                action: "list_studies",
                            }
                        ],
                    },
                    {
                        name: "Campañas",
                        href: menuRoutes.generalConfiguration.path + menuRoutes.generalConfiguration.campaigns,
                        permissions_required: [
                            {
                                group: "campaigns",
                                action: "list_campaigns",
                            }
                        ],
                    },
                ]
            },
        ]
    },
    superAdminMenu: {
        id: "title-super-admin",
        name: "Panel Administración",
        icon: "GreenLeafIcon",
        react_icon: PiFolder,
        type: "enhance",
        columns: "1",
        permissions_required: [
            {
                group: "companies",
                action: "admin_company",
                isSuperAdmin: true,
            },
        ],
        dropdownItems: [
            {
                id: "organisations",
                name: "Organizaciones",
                path: menuRoutes.organisations.path,
                icon: "ApartmentIcon",
                react_icon: PiGridFourDuotone,
                permissions_required: [
                    {
                        group: "companies",
                        action: "admin_company",
                        isSuperAdmin: true,
                    },
                ],
                subMenuItems: [
                    {
                        name: "Organizaciones",
                        href: menuRoutes.organisations.path,
                        permissions_required: [
                            {
                                group: "companies",
                                action: "list_company",
                            }
                        ],
                    },
                ]
            },
            {
                id: "roles",
                name: "Roles",
                path: menuRoutes.roles.path,
                icon: "RolesIcon",
                react_icon: PiNewspaperClippingDuotone,
                permissions_required: [
                    {
                        group: "roles",
                        action: "admin_roles",
                        isSuperAdmin: true,
                    },
                ],
                subMenuItems: [
                    {
                        name: "Lista Roles",
                        href: menuRoutes.roles.path,
                        permissions_required: [
                            {
                                group: "roles",
                                action: "list_roles",
                            }
                        ],
                    },
                    {
                        name: "Crear Rol",
                        href: menuRoutes.roles.path + '/' + menuRoutes.roles.create,
                        permissions_required: [
                            {
                                group: "roles",
                                action: "create_roles",
                            }
                        ],
                    },
                ]
            },
        ],
    },
};

export const tutorMenuItems: HeaderMenuItem = {
    myTutorials: {
        id: "my-tutorials",
        name: "Tutorías",
        path: menuRoutes.tutor_panel.tutorials,
        type: "enhance",
    },
    myCalendar: {
        id: "my-calendar",
        name: "Calendario",
        path: menuRoutes.tutor_panel.calendar,
        type: "enhance",
    },
    myInvoices: {
        id: "my-invoices",
        name: "Facturas",
        path: menuRoutes.tutor_panel.invoices,
        type: "enhance",
    },
    myDocuments: {
        id: "my-documents",
        name: "Documentos",
        path: menuRoutes.tutor_panel.documents,
        type: "enhance",
    },
    myProfile: {
        id: "my-profile",
        name: "Mi perfil",
        path: menuRoutes.tutor_panel.profile,
        type: "enhance",
    },
};