import React, { useMemo } from 'react';
import { Calendar, momentLocalizer, View } from 'react-big-calendar';
import { cn } from 'rizzui';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import moment from 'moment';
moment.locale('es-ES');

interface CustomCalendarProps {
    events: any[];
    className?: string;
    selectable?: boolean;
    resizable?: boolean;
    defaultView?: string;
    handleSelectEvent?: (event: any) => void;
    handleSelectSlot?: (slotInfo: any) => void;
    handleEventDrop?: (event: any) => void;
    handleEventResize?: (event: any) => void;
    handleView?: (view: string) => void;
    handleDrillDown?: (date: Date) => void;
    handleNavigate?: (date: Date) => void;
}

const DnDCalendar = withDragAndDrop(Calendar);

const localizer = momentLocalizer(moment);
const messages = {
    today: 'Hoy',
    previous: 'Anterior',
    next: 'Siguiente',
    yesterday: 'Ayer',
    tomorrow: 'Mañana',
    month: 'Mes',
    week: 'Semana',
    work_week: 'Semana laboral',
    day: 'Día',
    allDay: 'Todo el día',
    agenda: 'Agenda',
    date: 'Fecha',
    time: 'Hora',
    event: 'Evento',
    noEventsInRange: 'No hay eventos en estas fechas',
    showMore: (total: number) => `+ Ver más (${total})`,
};

const calendarToolbarClassName = '[&_.rbc-toolbar_.rbc-toolbar-label]:whitespace-nowrap [&_.rbc-toolbar_.rbc-toolbar-label]:my-2 [&_.rbc-toolbar]:flex [&_.rbc-toolbar]:flex-col [&_.rbc-toolbar]:items-center @[56rem]:[&_.rbc-toolbar]:flex-row [&_.rbc-btn-group_button:hover]:bg-gray-300 [&_.rbc-btn-group_button]:duration-200 [&_.rbc-btn-group_button.rbc-active:hover]:bg-gray-600 dark:[&_.rbc-btn-group_button.rbc-active:hover]:bg-gray-300 [&_.rbc-btn-group_button.rbc-active:hover]:text-gray-50 dark:[&_.rbc-btn-group_button.rbc-active:hover]:text-gray-900 [@media(max-width:375px)]:[&_.rbc-btn-group:last-child_button]:!px-2.5 [&_.rbc-toolbar_>_*:last-child_>_button:focus]:!bg-primary [&_.rbc-toolbar_>_*:last-child_>_button:focus]:!text-gray-0 dark:[&_.rbc-toolbar_>_*:last-child_>_button:focus]:!text-gray-900 [&_.rbc-toolbar_>_*:last-child_>_button:hover]:!text-gray-900 dark:[&_.rbc-toolbar_>_*:last-child_>_button:hover]:!bg-gray-300 [&_.rbc-toolbar_>_*:last-child_>_button:hover]:!bg-gray-300 [&_.rbc-toolbar_>_*:last-child_>_button.rbc-active:hover]:!bg-primary-dark [&_.rbc-toolbar_>_*:last-child_>_button.rbc-active:hover]:!text-gray-0 dark:[&_.rbc-toolbar_>_*:last-child_>_button.rbc-active:hover]:!text-gray-900';
const rtcEventClassName = '[&_.rbc-event]:!text-gray-0 dark:[&_.rbc-event]:!text-gray-0 dark:[&_.rbc-toolbar_>_*:last-child_>_button.rbc-active:hover]:!text-gray-0 dark:[&_.rbc-toolbar_>_*:last-child_>_button.rbc-active:focus]:!text-gray-0';

const CustomCalendar: React.FC<CustomCalendarProps> = ({
    events,
    className = 'h-[700px]',
    selectable = false,
    resizable = false,
    defaultView = 'month',
    handleSelectEvent,
    handleSelectSlot,
    handleEventDrop,
    handleEventResize,
    handleView,
    handleDrillDown,
    handleNavigate
}) => {

    const { views, scrollToTime } = useMemo(
        () => ({
            views: {
                month: true,
                week: true,
                day: true,
                agenda: true,
            },
            scrollToTime: new Date(),
        }),
        []
    );

    return (
        <div className="@container">
            <DnDCalendar
                selectable={selectable}
                resizable={resizable}
                localizer={localizer}
                culture="es"
                messages={messages}
                formats={{
                    monthHeaderFormat: (date: Date) => localizer.format(date, 'MMMM yyyy', 'es-Es'),
                    weekdayFormat: (date: Date) => localizer.format(date, 'ddd', 'es-Es'),
                    dayHeaderFormat: (date: Date) => localizer.format(date, 'dddd D MMM', 'es-Es'),
                }}
                views={views}
                defaultView={defaultView as View}
                dayLayoutAlgorithm="no-overlap"
                events={events}
                onSelectEvent={handleSelectEvent}
                onSelectSlot={handleSelectSlot}
                onEventDrop={handleEventDrop}
                onEventResize={handleEventResize}
                onView={handleView}
                onDrillDown={handleDrillDown}
                onNavigate={handleNavigate}
                scrollToTime={scrollToTime}
                className={cn(className, calendarToolbarClassName, rtcEventClassName)}
            />
        </div>
    );
};

export default CustomCalendar;