import React, { useCallback, useState } from 'react';
import ContractProfileLayout from '../../ContractProfileLayout';
import { Link, useParams } from 'react-router-dom';
import useHandleErrors from '../../../../../hooks/useHandleErrors';
import { usePrivilege } from '../../../../../components/priviledge/PriviledgeProvider';
import { ContractService } from '../../../../../services/contract/contractService';
import useFilters from '../../../../../hooks/useFilters';
import useFetch from '../../../../../hooks/useFetch';
import { ActionIcon, Tooltip } from 'rizzui';
import { FaPrint } from 'react-icons/fa6';
import { menuRoutes } from '../../../../../router/menu';
import { MdOutlineModeEditOutline } from 'react-icons/md';
import { InvoicesApiResponse } from '../../../../../type/entities/invoice-type';
import { FilterDrawerView } from '../../../../../components/table/components/TableFilter';
import { Loader } from '../../../../../components/loader/SpinnerLogo';
import CustomTable from '../../../../../components/table/CustomTable';
import moment from 'moment';
import { fixNumber } from '../../../../../utils/formatNumber';
import StatusDropdown from '../../../../../components/forms/StatusDropdown';
import useInvoiceStates from '../../../../../hooks/api-calls/useInvoiceStates';
import useHandleDownloadDocument from '../../../../../hooks/useDownloadDocument';
import { InvoiceService } from '../../../../../services/invoice/invoiceService';
import { toast } from 'sonner';

interface ContractInvoicingProps { }

const columnOrder = [
    'id',
    'number',
    'totalPrice',
    'taxableAmount',
    'tax',
    'totalTaxesAmount',
    'totalProducts',
    'currentStatus',
    'corporation',
    'invoiceDate',
    'paymentDate',
    'expirationDate',
    'createdAt',
    'updatedAt',
    'invoiceDates',
];

const ContractInvoicing: React.FC<ContractInvoicingProps> = ({ }) => {

    const { id = '' } = useParams<{ id: string }>();
    const { handleErrors } = useHandleErrors();
    const { userCan } = usePrivilege();
    const service = new ContractService();
    const { handleDownloadDocumentNewTab } = useHandleDownloadDocument();
    const { getInvoiceStatesList } = useInvoiceStates({ active: true });

    const { filters, updateFilters, updateFilterOrder, updatePage, updatePageSize, resetFilters } = useFilters({ client: id });

    const [openFilters, setOpenFilters] = useState<boolean>(false);

    const [data, loading, error, refetch] = useFetch(useCallback(async () => {
        if (!id || id === '') return null;
        const response = await service.listContractInvoicing(filters);
        return response.getResponseData();
    }, [id, filters]));

    const handleStateChange = async (invoiceId: string, newStateId: string, comment: string) => {
        try {
            const response = await (new InvoiceService()).changeInvoiceState(invoiceId, newStateId, comment);
            const responseData = response.getResponseData();

            if (responseData && responseData.success) {
                toast.success("Estado de la factura actualizado correctamente");
                refetch();
            } else if (responseData) {
                toast.error(responseData.message);
                refetch();
            }
        } catch (error: any) {
            handleErrors(error);
        }
    };

    /**
     * Order the data to match the column order
     */
    const orderedData = data?.data.map((row: any) => {
        const orderedRow: any = {};
        columnOrder.forEach((key) => {
            orderedRow[key] = row[key];
        });
        return orderedRow;
    });

    return (
        <ContractProfileLayout isLoading={loading}>
            {loading
                ? <Loader height='70vh' />
                : (
                    <CustomTable
                        id={'contract-invoicing-table'}
                        data={orderedData}
                        columnOrder={columnOrder}
                        columnsNotShown={['company', 'lines', 'invoiceDate', 'paymentDate', 'expirationDate', 'invoiceDates']}
                        additionalColumns={[
                            {
                                id: 'invoiceDates',
                                header: 'Fechas Factura',
                                render: (row: any) => {
                                    const invoiceDate = row.row.original?.invoiceDate.date;
                                    const paymentDate = row.row.original?.paymentDate?.date;
                                    const expirationDate = row.row.original?.expirationDate?.date;
                                    return (
                                        <ul className='gap-x-4'>
                                            {invoiceDate && (
                                                <li className='flex flex-nowrap pb-1'>
                                                    <h6 className='text-nowrap text-gray-400'>Fecha de Factura:&nbsp;</h6>
                                                    {invoiceDate ? moment(invoiceDate).format('DD/MM/YY') : 'N/A'}
                                                </li>
                                            )}
                                            {paymentDate && (
                                                <li className='flex flex-nowrap pb-1'>
                                                    <h6 className='text-nowrap text-gray-400'>Fecha de Pago:&nbsp;</h6>
                                                    {paymentDate ? moment(paymentDate).format('DD/MM/YY') : 'N/A'}
                                                </li>
                                            )}
                                            {expirationDate && (
                                                <li className='flex flex-nowrap pb-1'>
                                                    <h6 className='text-nowrap text-gray-400'>Fecha de Vencimiento:&nbsp;</h6>
                                                    {expirationDate ? moment(expirationDate).format('DD/MM/YY') : 'N/A'}
                                                </li>
                                            )}
                                        </ul>
                                    );
                                }
                            },
                        ]}
                        overrideColumns={[
                            {
                                key: 'number',
                                columnNameKey: 'invoiceNumber',
                                render: (row: any) => {
                                    return row.row.original?.number || 'N/A';
                                },
                            },
                            {
                                key: 'totalPrice',
                                render: (row: any) => {
                                    return (
                                        <div className="text-center">
                                            {row.row.original?.totalPrice ? `${fixNumber(row.row.original?.totalPrice)}€` : 'N/A'}
                                        </div>
                                    )
                                },
                            },
                            {
                                key: 'taxableAmount',
                                render: (row: any) => {
                                    return (
                                        <div className="text-center">
                                            {row.row.original?.taxableAmount ? `${fixNumber(row.row.original?.taxableAmount)}€` : 'N/A'}
                                        </div>
                                    )
                                },
                            },
                            {
                                key: 'currentStatus',
                                render: (row: any) => {
                                    return (
                                        <Tooltip
                                            size="sm"
                                            content={row.row.original?.currentStatus?.description}
                                            placement="top"
                                            color="invert"
                                        >
                                            <StatusDropdown
                                                entityId={row.row.original?.id}
                                                currentState={row.row.original?.currentStatus}
                                                statesOptions={getInvoiceStatesList(row.row.original?.currentStatus?.id)}
                                                handleStateChange={handleStateChange}
                                                title="Esta acción modificará el estado de la factura"
                                            />
                                        </Tooltip>
                                    );
                                }
                            },
                            {
                                key: 'corporation',
                                render: (row: any) => {
                                    return row.row.original?.corporation?.name || 'N/A';
                                },
                            },
                            {
                                key: 'tax',
                                render: (row: any) => {
                                    return (
                                        row.row.original?.tax ? `${row.row.original?.tax}%` : 'N/A'
                                    );
                                },
                            },
                            {
                                key: 'totalTaxesAmount',
                                render: (row: any) => {
                                    return (
                                        <div className="text-center">
                                            {row.row.original?.totalTaxesAmount ? `${fixNumber(row.row.original?.totalTaxesAmount)}€` : 'N/A'}
                                        </div>
                                    )

                                },
                            },
                            {
                                key: 'totalProducts',
                                render: (row: any) => {
                                    return (
                                        <div className="text-center">
                                            {row.row.original?.totalProducts ? `${row.row.original?.totalProducts}` : 'N/A'}
                                        </div>
                                    )
                                },
                            },
                        ]}
                        actions={[
                            /* {
                                label: 'Generar PDF',
                                permissions: { group: 'invoices', permission: 'print_invoices' },
                                render: (row: any) => {
                                    return (
                                        <Tooltip
                                            size="sm"
                                            content={'Generar PDF'}
                                            placement="top"
                                            color="invert"
                                        >
                                            <ActionIcon
                                                as="span"
                                                size="sm"
                                                variant="outline"
                                                className="hover:!border-gray-900 hover:text-gray-700 cursor-pointer"
                                                onClick={() => handleDownloadDocumentNewTab(service.generateInvoicePdf, row.id, moment().format('DD-MM-YYYY') + '_factura.pdf')}
                                            >
                                                <FaPrint className="h-4 w-4" size={20} />
                                            </ActionIcon>
                                        </Tooltip>
                                    );
                                },
                            }, */
                            {
                                label: 'Edit',
                                permissions: { group: 'invoices', permission: 'edit_invoices' },
                                render: (row: any) => {
                                    return (
                                        <Tooltip
                                            size="sm"
                                            content={'Editar factura'}
                                            placement="top"
                                            color="invert"
                                        >
                                            <Link to={`${menuRoutes.invoices.path}/${row.id}/edit`}>
                                                <ActionIcon
                                                    as="span"
                                                    size="sm"
                                                    variant="outline"
                                                    className="hover:!border-gray-900 hover:text-gray-700"
                                                >
                                                    <MdOutlineModeEditOutline className="h-4 w-4" size={20} />
                                                </ActionIcon>
                                            </Link>
                                        </Tooltip>
                                    );
                                },
                            }
                        ]}
                        filters={filters}
                        updateFilters={updateFilters}
                        updateFilterOrder={updateFilterOrder}
                        defaultOrder={filters.filter_order || undefined}
                        paginationData={{
                            pageSize: filters.limit,
                            currentPage: filters.page,
                            pageCount: (data as InvoicesApiResponse) ? data.lastPage : 1,
                            totalCount: data?.totalRegisters,
                            handlePagination: updatePage,
                            handlePerPage: updatePageSize,
                        }}
                        toggleFilters={() => setOpenFilters(!openFilters)}
                    />
                )}

            <FilterDrawerView isOpen={openFilters} setOpenDrawer={setOpenFilters} drawerTitle={'Filtros Facturas (Contrato)'}>
                {/* <ContractInvoicesFilters filters={filters} updateFilters={updateFilters} resetFilters={resetFilters} /> */}
            </FilterDrawerView>
        </ContractProfileLayout>
    );
};

export default ContractInvoicing;