// import ContractProfileLayout from "../../ContractProfileLayout";

// const ContractDocuments = () => {
//     return (
//         <ContractProfileLayout>
//             <div className="@container">
//                 <div className="grid divide-y-2 divide-dashed divide-gray-200 gap-6 mt-3">
//                     <div className={`flex flex-col gap-7 w-full transition-all duration-1000 ease-in-out md:h-[calc(100vh-330px)] md:overflow-y-scroll md:relative pe-2 scrollbar`} >
//                     </div>
//                 </div>
//             </div>
//         </ContractProfileLayout>
//     );
// }

// export default ContractDocuments;

import React, { useCallback, useEffect, useState } from 'react';
import ContractProfileLayout from "../../ContractProfileLayout";
import useFetch from '../../../../../hooks/useFetch';
import useFilters from '../../../../../hooks/useFilters';
import { useParams } from 'react-router-dom';
import Dropzone from '../../../../../components/documents/Dropzone';
import RecentDocuments from '../../../../../components/documents/RecentDocuments';
import CustomTable from '../../../../../components/table/CustomTable';
import DeletePopover from '../../../../../components/buttons/DeletePopover';
import moment from 'moment';
import { DocumentsService } from '../../../../../services/document/documentsService';
import useHandleDownloadDocument from '../../../../../hooks/useDownloadDocument';
import { ActionIcon, Tooltip } from 'rizzui';
import { IoMdDownload } from "react-icons/io";
import { FilterDrawerView } from '../../../../../components/table/components/TableFilter';
import DocumentsFilters from '../documents/DocumentsFilters';
import { ContractService } from '../../../../../services/contract/contractService';

interface ContractDocumentsProps { }

const columnOrder = [
    'id',
    'originalName',
    'documentType',
    'user',
    'extension',
    'createdAt',
];

const ContractDocuments: React.FC<ContractDocumentsProps> = ({ }) => {

    const { id = '' } = useParams<{ id: string }>();
    const { filters, updateFilters, updateFilterOrder, updatePage, updatePageSize, resetFilters } = useFilters();
    const { handleDownloadDocumentNewTab } = useHandleDownloadDocument();
    const docService = new DocumentsService();
    const service = new ContractService();

    const [updateList, setUpdateList] = useState<boolean>(false);
    const [openFilters, setOpenFilters] = useState<boolean>(false);

    const [data, loading, error, refetch] = useFetch(useCallback(async () => {
        // if (!filters.filter_filters?.contractId) return [];
        // const response = await service.listContractDocuments(filters);
        // return response.getResponseData();
        return {
            "success": true,
            "data": {
                "totalRegisters": 0,
                "data": [],
                "lastPage": 0,
                "filters": {
                    "filter_order": [],
                    "filter_filters": {},
                    "limit": 50,
                    "page": 1
                }
            },
            "message": "Listado de documentos de tutores obtenido con exito"
        };
    }, [filters]));

    const handleMultiDelete = async (ids: string[]) => {
        // await docService.multiDeleteDocuments(ids);
        // setUpdateList(true);
        // refetch();
    };

    /**
     * Order the data to match the column order
     */
    const orderedData = data?.data.map((row: any) => {
        const formattedRow: any = {
            id: row.document.id,
            originalName: row.document.originalName,
            documentType: row.documentType.name,
            contract: row.contract.code,
            extension: row.document.extension,
            createdAt: moment(row.createdAt.date).format('DD/MM/YYYY'),
        };

        const orderedRow: any = {};
        columnOrder.forEach((column) => {
            orderedRow[column] = formattedRow[column];
        });

        return orderedRow;
    });

    useEffect(() => {
        updateFilters({ contractId: id });
    }, [id]);

    useEffect(() => {
        if (updateList) {
            refetch();
            setUpdateList(false);
        }
    }, [updateList]);

    return (
        <ContractProfileLayout>
            <div className="container mx-auto p-4">
                <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
                    <div className="md:col-span-3 col-span-1">
                        <div className="pt-7 mb-10 grid gap-7 divide-y-2 divide-dashed divide-gray-200">
                            {data !== undefined && data && (
                                <CustomTable
                                    id={'contracts-documents-table'}
                                    columnOrder={columnOrder}
                                    data={orderedData}
                                    isLoading={loading}
                                    columnsNotShown={['extension', 'fileName', 'status']}
                                    actions={[
                                        {
                                            label: 'Descargar',
                                            render: (row: any) => {
                                                return (
                                                    <Tooltip
                                                        size="sm"
                                                        content={'Descargar documento'}
                                                        placement="top"
                                                        color="invert"
                                                    >
                                                        <ActionIcon
                                                            as="span"
                                                            size="sm"
                                                            variant="outline"
                                                            className="hover:!border-gray-900 hover:text-gray-700 cursor-pointer"
                                                            onClick={() => handleDownloadDocumentNewTab(docService.downloadDocument, row.id, row.originalName, row.extension)}
                                                        >
                                                            <IoMdDownload className="h-4 w-4" size={20} />
                                                        </ActionIcon>
                                                    </Tooltip>
                                                );
                                            },
                                        },
                                        {
                                            label: 'Delete',
                                            render: (row: any) => {
                                                return (
                                                    <DeletePopover
                                                        title={`Eliminar documento`}
                                                        description={`¿Estás seguro de que deseas eliminar ${row.originalName}?`}
                                                        onDelete={() => handleMultiDelete([row.id])}
                                                    />
                                                );
                                            },
                                        },
                                    ]}
                                    handleMultipleDelete={handleMultiDelete}
                                    filters={filters}
                                    updateFilters={updateFilters}
                                    updateFilterOrder={updateFilterOrder}
                                    defaultOrder={filters.filter_order || undefined}
                                    paginationData={{
                                        pageSize: filters.limit,
                                        currentPage: filters.page,
                                        pageCount: data ? data.lastPage : 1,
                                        totalCount: data?.totalRegisters,
                                        handlePagination: updatePage,
                                        handlePerPage: updatePageSize,
                                    }}
                                    toggleFilters={() => setOpenFilters(!openFilters)}
                                />
                            )}
                        </div>

                        <Dropzone id={id} entity='contrato' setUpdateList={setUpdateList} />
                    </div>

                    <div className="md:col-span-1 col-span-1">
                        <RecentDocuments updateList={updateList} setUpdateList={setUpdateList} />
                    </div>
                </div>
            </div>

            <FilterDrawerView isOpen={openFilters} setOpenDrawer={setOpenFilters} drawerTitle={'Filtros Documentos'}>
                <div className="grid grid-cols-1 gap-6">
                    <DocumentsFilters filters={filters} updateFilters={updateFilters} resetFilters={resetFilters} />
                </div>
            </FilterDrawerView>
        </ContractProfileLayout>
    );
};

export default ContractDocuments;