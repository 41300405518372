import cn from "../../../utils/classNames";
import { ActionIcon, Button, Collapse, Loader, Text, Tooltip } from "rizzui";
import { FaRegCalendar } from "react-icons/fa";
import WidgetCard from "../../../components/card/WidgetCard";
import { PiCaretDownBold } from "react-icons/pi";
import useClientCommentsHistory from "../../../hooks/api-calls/contract/useClientComments";
import { usePrivilege } from "../../../components/priviledge/PriviledgeProvider";
import { useEffect, useState } from "react";
import { AddCommentModal } from "../../clients/list/components/AddCommentModal";
import { MdOutlineInsertComment } from "react-icons/md";
import { FaFaceFrown } from "react-icons/fa6";
import { ClientService } from "../../../services/client/clientService";
import moment from "moment";

interface ClientCommentHistoryProps {
    clientId: string;
    formik: any;
}
const ClientCommentHistory = ({ clientId, formik }: ClientCommentHistoryProps) => {

    const { userCan } = usePrivilege();
    const [openCommentModal, setOpenCommentModal] = useState(false);
    const rowLimit = 5;

    const { fetchClientCommentHistory, updateClientId, getClientCommentsList, isMoreClientComments, isLoadingClientComments, modifyLimitClientComments } = useClientCommentsHistory({ client: clientId }, {}, rowLimit);

    useEffect(() => {
        if (clientId) updateClientId(clientId);
    }, [clientId])


    function ClientCommentHistoryContent(arrayData: any) {

        return (
            <>
                <div className="space-y-2 p-0.5 divide-y-2 divide-dashed">
                    {arrayData.map((activity: any) => (
                        <div
                            key={activity.id}
                            className="group cursor-pointer pt-2 space-y-1 transition-shadow "
                        >
                            <div className="flex flex-col xl:flex-row flex-nowrap justify-between gap-8">
                                <div className="w-full">
                                    <div className="flex flex-col h-full">
                                        <div className="flex-grow overflow-hidden">
                                            <Tooltip size="md" content={<div className='tooltip-container'>{activity.title}</div>} placement="top" color="invert">
                                                <div>
                                                    <Text className="line-clamp-3">
                                                        {activity.title}
                                                    </Text>
                                                </div>
                                            </Tooltip>
                                        </div>
                                        <div className="flex justify-end mt-2">
                                            <div className="flex items-center gap-2 border-2 border-gray-100 rounded-lg p-2 py-1 text-sm">
                                                <FaRegCalendar className="text-gray-400" />
                                                <Text className="text-gray-400 text-nowrap">
                                                    {moment(activity.date).format('DD/MM/YYYY HH:mm')}
                                                </Text>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </>
        );
    }

    return (
        <div className={cn("my-3")}>
            <Collapse
                className="py-3"
                defaultOpen={false}
                header={({ open, toggle }) => (
                    <button
                        type="button"
                        onClick={toggle}
                        className="flex w-full cursor-pointer items-center justify-between text-base font-semibold text-gray-900"
                    >
                        <div>
                            Comentarios del cliente
                            <span className="text-gray-400">
                                &nbsp;&nbsp;
                                {getClientCommentsList().length >= 1 ?
                                    `(${getClientCommentsList().length})` : '(0)'}
                            </span>
                        </div>
                        <PiCaretDownBold
                            strokeWidth={3}
                            className={cn(
                                "h-3.5 w-3.5 -rotate-90 text-gray-500 transition-transform duration-200 rtl:rotate-90",
                                open && "rotate-0 rtl:rotate-0"
                            )}
                        />
                    </button>
                )}
            >
                <WidgetCard title="" headerClassName="hidden" className="border-transparent p-2 lg:p-3 slide-down">
                    <AddCommentModal service={(new ClientService).addComment} id={clientId} isOpen={openCommentModal} onClose={() => setOpenCommentModal(false)} refetch={fetchClientCommentHistory} />
                    <div className="flex flex-row justify-end w-full">
                    <Tooltip
                        size="sm"
                        content={'Añadir comentario'}
                        placement="top"
                        color="invert"
                    >
                        <ActionIcon
                            as="span"
                            size="sm"
                            variant="outline"
                            className="hover:!border-gray-900 hover:text-gray-700 cursor-pointer"
                            onClick={() => { setOpenCommentModal(true) }}
                        >
                            <MdOutlineInsertComment className="h-4 w-4" size={20} />
                        </ActionIcon>
                    </Tooltip>
                </div>
                    {!isLoadingClientComments && getClientCommentsList().length >= 1 ? (
                        ClientCommentHistoryContent(getClientCommentsList())
                    ) : (
                        <div className="flex items-center justify-center h-18 ">
                            {isLoadingClientComments ?
                                <Loader variant="spinner" /> :
                                <Text className="text-gray-400">Sin Resultados</Text>
                            }
                        </div>
                    )}
                    {userCan('get_clients_comments', 'clients') && userCan('get_clients', 'clients') && (
                        <div className="w-full flex flex-row gap-2 justify-center">
                            <Button
                                variant="outline"
                                className={cn("border-gray-300 w-[45%] mt-2", !isMoreClientComments && 'hidden')}
                                onClick={() => modifyLimitClientComments(rowLimit)}
                            >
                                <Text className="text-gray-400">Ver Más</Text>
                            </Button>
                            <Button
                                variant="outline"
                                className={cn("border-gray-300 w-[45%] mt-2", getClientCommentsList().length <= rowLimit && 'hidden')}
                                onClick={() => modifyLimitClientComments(-5)}
                            >
                                <Text className="text-gray-400">Ver Menos</Text>
                            </Button>
                        </div>
                    )}
                </WidgetCard>
            </Collapse>
        </div>
    )
}

export default ClientCommentHistory;