import { menuRoutes } from "./menu";
import Dashboard from "../pages/dashboard/Dashboard";
import UsersWrapper from "../pages/users";
import UsersListWrapper from "../pages/users/list/UsersListWrapper";
import OrganisationWrapper from "../pages/organisations";
import OrganisationsListWrapper from "../pages/organisations/list/OrganisationsListWrapper";
import OrganisationCreate from "../pages/organisations/create/OrganisationCreate";
import OrganisationEdit from "../pages/organisations/edit/OrganisationEdit";
import UserEdit from "../pages/users/edit/UserEdit";
import UserCreate from "../pages/users/create/UserCreate";
import UserInfo from "../pages/users/profile/views/info/UserInfo";
import RolesWrapper from "../pages/roles";
import RolesListWrapper from "../pages/roles/list/RolesListWrapper";
import RoleCreate from "../pages/roles/create/RoleCreate";
import RoleEdit from "../pages/roles/edit/RoleEdit";
import UserPermissions from "../pages/users/profile/views/permissions/UserPermissions";
import UserDocuments from "../pages/users/profile/views/documents/UserDocuments";
import UserChangelogsWrapper from "../pages/users/profile/views/activity-history/UserActivityHistoryWrapper";
import UserEditPermissions from "../pages/users/edit/UserEditPermissions";
import ProductsWrapper from "../pages/products";
import ProductsListWrapper from "../pages/products/list/ProductsListWrapper";
import ProductCreate from "../pages/products/create/ProductCreate";
import ProductEdit from "../pages/products/edit/ProductEdit";
import UserStats from "../pages/users/profile/views/stats/UserStats";
import CorporationsWrapper from "../pages/corporations";
import CorporationsListWrapper from "../pages/corporations/list/CorporationsListWrapper";
import StatesWrapper from "../pages/states";
import StatesListWrapper from "../pages/states/list/StatesListWrapper";
import StateEdit from "../pages/states/edit/StateEdit";
import StateCreate from "../pages/states/create/StateCreate";
import TutorsWrapper from "../pages/tutors";
import TutorsListWrapper from "../pages/tutors/list/TutorsListWrapper";
import TutorCreate from "../pages/tutors/create/TutorCreate";
import TutorEdit from "../pages/tutors/edit/TutorEdit";
import NetworksWrapper from "../pages/networks";
import NetworksListWrapper from "../pages/networks/list/NetworksListWrapper";
import DepartmentsWrapper from "../pages/departments";
import DepartmentsListWrapper from "../pages/departments/list/DepartmentsListWrapper";
import TutorInfo from "../pages/tutors/profile/views/info/TutorInfo";
import TutorStats from "../pages/tutors/profile/views/stats/TutorStats";
import TutorDocuments from "../pages/tutors/profile/views/documents/TutorDocuments";
import InvoicesWrapper from "../pages/invoicing";
import InvoicesListWrapper from "../pages/invoicing/list/InvoicesListWrapper";
import TutorsLayoutWrapper from "../pages/_tutor-layout";
import TutorProfile from "../pages/_tutor-layout/profile/TutorProfile";
import ListMyDocumentsWrapper from "../pages/_tutor-layout/documents/ListMyDocumentsWrapper";
import InvoiceEdit from "../pages/invoicing/edit/InvoiceEdit";
import TutorInvoices from "../pages/tutors/profile/views/invoices/TutorInvoices";
import ListMyInvoicesWrapper from "../pages/_tutor-layout/invoices/ListMyInvoicesWrapper";
import StudentsWrapper from "../pages/students";
import StudentsListWrapper from "../pages/students/list/StudentsListWrapper";
import StudentCreate from "../pages/students/create/StudentCreate";
import StudentEdit from "../pages/students/edit/StudentEdit";
import ClientCreate from "../pages/clients/create/ClientCreate";
import ClientEdit from "../pages/clients/edit/ClientEdit";
import ClientsListWrapper from "../pages/clients/list/ClientsListWrapper";
import ClientInfo from "../pages/clients/profile/views/info/ClientInfo";
import ClientStudents from "../pages/clients/profile/views/students/ClientStudents";
import ClientComments from "../pages/clients/profile/views/comments/ClientComments";
import ContractCreate from "../pages/contracts/create/ContractCreate";
import ContractEdit from "../pages/contracts/edit/ContractEdit";
import ContractsLayoutWrapper from "../pages/contracts";
import ContractsListWrapper from "../pages/contracts/list/ContractListWrapper";
import ClientCreditHistory from "../pages/clients/profile/views/credit-history/ClientCreditHistory";
import ClientConsultancy from "../pages/clients/profile/views/consultancy/ClientConsultancy";
import GeneralConfigurationWrapper from "../pages/general-configuration";
import ConfigListWrapper from "../pages/general-configuration/ConfigListWrapper";
import ClientsWrapper from "../pages/clients";
import ClientProducts from "../pages/clients/profile/views/products/ClientProducts";
import ClientCalls from "../pages/clients/profile/views/calls/ClientCalls";
import MyTutorials from "../pages/_tutor-layout/tutorials/MyTutorials";
import MyCalendar from "../pages/_tutor-layout/calendar/MyCalendar";
import TutorContracts from "../pages/tutors/profile/views/contracts/TutorContracts";
import ConsultanciesWrapper from "../pages/consultancy";
import ConsultanciesListWrapper from "../pages/consultancy/list/ConsultanciesListWrapper";
import CreateConsultancy from "../pages/consultancy/create/CreateConsultancy";
import EditConsultancy from "../pages/consultancy/edit/EditConsultancy";
import UserContractsWrapper from "../pages/users/profile/views/contracts/UserContractsWrapper";
import ClientContractsWrapper from "../pages/clients/profile/views/contracts/ClientContractsWrapper";
import ClientActivityHistoryWrapper from "../pages/clients/profile/views/activity-history/ClientActivityHistoryWrapper";
import ClientInvoicesWrapper from "../pages/clients/profile/views/invoices/ClientInvoicesWrapper";
import SettingsWrapper from "../pages/settings";
import ContractsSettingsPage from "../pages/settings/ContractsSettingsPage";
import HolidaysSettingsPage from "../pages/settings/holidays/HolidaysSettingsPage";
import InvoicingsSettingsPage from "../pages/settings/InvoicingsSettingsPage";
import ContractInfo from "../pages/contracts/profile/views/info/ContractInfo";
import ContractDocuments from "../pages/contracts/profile/views/documents/ContractDocuments";
import ContractStatesHistory from "../pages/contracts/profile/views/states-history/ContractStatesHistory";
import ContractChangelogsWrapper from "../pages/contracts/profile/views/activity-history/ContractActivityHistoryWrapper";
import StudentActivityHistoryWrapper from "../pages/students/profile/views/activity-history/StudentActivityHistoryWrapper";
import StudentInfo from "../pages/students/profile/views/info/StudentInfo";
import InvoiceInfo from "../pages/invoicing/profile/views/info/InvoiceInfo";
import InvoiceActivityHistoryWrapper from "../pages/invoicing/profile/views/activity-history/InvoiceActivityHistoryWrapper";
import ContractComments from "../pages/contracts/profile/views/comments/ContractComments";
import ContractInvoicing from "../pages/contracts/profile/views/invoicing/ContractInvoicing";

const protectedRoutes = [
	/******************************************************
	 * Dashboard
	 ******************************************************/
	{
		path: menuRoutes.dashboard.path,
		element: <Dashboard />,
		access: {
			group: "dashboard",
			action: "get_dashboard_information"
		},
	},

	/******************************************************
	 * Organisations
	 ******************************************************/
	{
		path: menuRoutes.organisations.path,
		element: <OrganisationWrapper />,
		sub: [
			{
				element: <OrganisationsListWrapper />,
				access: {
					group: "companies",
					action: "list_company",
				},
				index: true,
			},
			{
				element: <OrganisationCreate />,
				path: menuRoutes.organisations.create,
				access: {
					group: "companies",
					action: "create_company"
				}
			},
			{
				element: <OrganisationEdit />,
				path: ':id/edit',
				access: {
					group: "companies",
					action: "edit_company"
				}
			}
		],
	},

	/******************************************************
	 * Roles
	 ******************************************************/
	{
		path: menuRoutes.roles.path,
		element: <RolesWrapper />,
		sub: [
			{
				element: <RolesListWrapper />,
				access: {
					group: 'roles',
					action: 'list_roles'
				},
				index: true,
			},
			{
				element: <RoleCreate />,
				path: menuRoutes.roles.create,
				access: {
					group: 'roles',
					action: 'create_roles'
				}
			},
			{
				element: <RoleEdit />,
				path: menuRoutes.roles.edit,
				access: {
					group: 'roles',
					action: 'edit_roles'
				}
			},
		]
	},

	/******************************************************
	 * Users
	 ******************************************************/
	{
		path: menuRoutes.users.path,
		element: <UsersWrapper />,
		sub: [
			{
				element: <UsersListWrapper />,
				access: {
					group: 'user',
					action: 'list_user'
				},
				index: true,
			},
			{
				element: <UserInfo />,
				path: menuRoutes.users.profile.path + menuRoutes.users.profile.info,
				access: {
					group: 'user',
					action: 'get_user'
				},
			},
			{
				element: <UserStats />,
				path: menuRoutes.users.profile.path + menuRoutes.users.profile.stats,
				access: {
					group: 'user',
					action: 'user_profile_stats'
				},
			},
			{
				element: <UserDocuments />,
				path: menuRoutes.users.profile.path + menuRoutes.users.profile.documents,
				access: {
					group: 'user',
					action: 'user_profile_documents'
				},
			},
			{
				element: <UserPermissions />,
				path: menuRoutes.users.profile.path + menuRoutes.users.profile.permissions,
				access: {
					group: 'user',
					action: 'user_profile_permissions'
				},
			},
			{
				element: <UserContractsWrapper />,
				path: menuRoutes.users.profile.path + menuRoutes.users.profile.contract_history,
				access: {
					group: 'user',
					action: 'user_profile_contract_history'
				},
			},
			{
				element: <UserChangelogsWrapper />,
				path: menuRoutes.users.profile.path + menuRoutes.users.profile.activity,
				access: {
					group: 'user',
					action: 'user_profile_activity'
				},
			},
			{
				element: <UserCreate />,
				path: menuRoutes.users.create,
				access: {
					group: 'user',
					action: 'create_user'
				}
			},
			{
				element: <UserEdit />,
				path: menuRoutes.users.edit,
				access: {
					group: 'user',
					action: 'edit_user'
				}
			},
			{
				element: <UserEditPermissions />,
				path: menuRoutes.users.edit_permissions,
				access: {
					group: 'user',
					action: 'edit_user'
				}
			},
		]
	},

	/******************************************************
	 * Tutors
	 ******************************************************/
	{
		path: menuRoutes.tutors.path,
		element: <TutorsWrapper />,
		sub: [
			{
				element: <TutorsListWrapper />,
				access: {
					group: 'tutors',
					action: 'list_tutors'
				},
				index: true,
			},
			{
				element: <TutorInfo />,
				path: menuRoutes.tutors.profile.path + menuRoutes.tutors.profile.info,
				access: {
					group: 'tutors',
					action: 'get_tutors'
				},
			},
			{
				element: <TutorStats />,
				path: menuRoutes.tutors.profile.path + menuRoutes.tutors.profile.stats,
				access: {
					group: 'tutors',
					action: 'tutors_stats'
				},
			},
			{
				element: <TutorDocuments />,
				path: menuRoutes.tutors.profile.path + menuRoutes.tutors.profile.documents,
				access: {
					group: 'tutors',
					action: 'tutors_documentation'
				},
			},
			{
				element: <TutorInvoices />,
				path: menuRoutes.tutors.profile.path + menuRoutes.tutors.profile.invoices,
				access: {
					group: 'tutors',
					action: 'tutors_invoices'
				},
			},
			{
				element: <TutorContracts />,
				path: menuRoutes.tutors.profile.path + menuRoutes.tutors.profile.contracts,
				access: {
					group: 'tutors',
					action: 'tutors_pending_contracts'
				},
			},
			{
				element: <TutorCreate />,
				path: menuRoutes.tutors.create,
				access: {
					group: 'tutors',
					action: 'create_tutors'
				}
			},
			{
				element: <TutorEdit />,
				path: menuRoutes.tutors.edit,
				access: {
					group: 'tutors',
					action: 'edit_tutors'
				}
			},
		]
	},

	/******************************************************
	 * Products
	 ******************************************************/
	{
		path: menuRoutes.products.path,
		element: <ProductsWrapper />,
		sub: [
			{
				element: <ProductsListWrapper />,
				access: {
					group: 'products',
					action: 'list_products'
				},
				index: true,
			},
			{
				element: <ProductCreate />,
				path: menuRoutes.products.create,
				access: {
					group: 'products',
					action: 'create_products'
				}
			},
			{
				element: <ProductEdit />,
				path: menuRoutes.products.edit,
				access: {
					group: 'products',
					action: 'edit_products'
				}
			}
		]
	},

	/******************************************************
	 * States
	 ******************************************************/
	{
		path: menuRoutes.states.path,
		element: <StatesWrapper />,
		sub: [
			{
				element: <StatesListWrapper />,
				access: {
					group: 'statuses',
					action: 'admin_statuses'
				},
				index: true,
			},
			{
				element: <StatesListWrapper />,
				path: menuRoutes.states.path + menuRoutes.states.list.contract,
			},
			{
				element: <StatesListWrapper />,
				path: menuRoutes.states.path + menuRoutes.states.list.contract_product,
			},
			{
				element: <StatesListWrapper />,
				path: menuRoutes.states.path + menuRoutes.states.list.contract_invoicing,
			},
			{
				element: <StatesListWrapper />,
				path: menuRoutes.states.path + menuRoutes.states.list.contract_incidence,
			},
			{
				element: <StatesListWrapper />,
				path: menuRoutes.states.path + menuRoutes.states.list.expiration,
			},
			{
				element: <StatesListWrapper />,
				path: menuRoutes.states.path + menuRoutes.states.list.invoice,
			},
			{
				element: <StatesListWrapper />,
				path: menuRoutes.states.path + menuRoutes.states.list.incidence,
			},
			{
				element: <StatesListWrapper />,
				path: menuRoutes.states.path + menuRoutes.states.list.renovation,
			},
			{
				element: <StateCreate />,
				path: menuRoutes.states.create,
				access: {
					group: 'statuses',
					action: 'create_statuses'
				}
			},
			{
				element: <StateEdit />,
				path: menuRoutes.states.edit,
				access: {
					group: 'statuses',
					action: 'edit_statuses'
				}
			},
		]
	},

	/******************************************************
	 * Invoices
	 ******************************************************/
	{
		path: menuRoutes.invoices.path,
		element: <InvoicesWrapper />,
		sub: [
			{
				element: <InvoicesListWrapper />,
				access: {
					group: 'invoices',
					action: 'list_invoices'
				},
				index: true,
			},
			{
				element: <InvoiceEdit />,
				path: menuRoutes.invoices.edit,
				access: {
					group: 'invoices',
					action: 'edit_invoices'
				}
			},
			{
				element: <InvoiceInfo />,
				path: menuRoutes.invoices.profile.path + menuRoutes.invoices.profile.info,
				access: {
					group: 'invoices',
					action: 'get_invoices'
				},
			},
			{
				element: <InvoiceActivityHistoryWrapper />,
				path: menuRoutes.invoices.profile.path + menuRoutes.invoices.profile.activityHistory,
				access: {
					group: 'invoices',
					action: 'invoice_changes_log',
				},
			}
		]
	},

	/******************************************************
	 * Corporations
	 ******************************************************/
	{
		path: menuRoutes.corporations.path,
		element: <CorporationsWrapper />,
		sub: [
			{
				element: <CorporationsListWrapper />,
				access: {
					group: 'corporation',
					action: 'list_corporation'
				},
				index: true,
			},
		]
	},

	/******************************************************
	 * Networks
	 ******************************************************/
	{
		path: menuRoutes.networks.path,
		element: <NetworksWrapper />,
		sub: [
			{
				element: <NetworksListWrapper />,
				access: {
					group: 'commercial_network',
					action: 'list_commercial_network'
				},
				index: true,
			},
		]
	},

	/******************************************************
	 * Departments
	 * ****************************************************/
	{
		path: menuRoutes.departments.path,
		element: <DepartmentsWrapper />,
		sub: [
			{
				element: <DepartmentsListWrapper />,
				access: {
					group: 'departments',
					action: 'list_departments'
				},
				index: true,
			},
		]
	},

	/******************************************************
	 * Clients
	 ******************************************************/
	{
		path: menuRoutes.clients.path,
		element: <ClientsWrapper />,
		sub: [
			{
				element: <ClientsListWrapper />,
				access: {
					group: 'clients',
					action: 'list_clients'
				},
				index: true,
			},
			{
				element: <ClientInfo />,
				path: menuRoutes.clients.profile.path + menuRoutes.clients.profile.info,
				access: {
					group: 'clients',
					action: 'get_clients'
				},
			},
			{
				element: <ClientStudents />,
				path: menuRoutes.clients.profile.path + menuRoutes.clients.profile.students,
				access: {
					group: 'clients',
					action: 'get_clients_students'
				},
			},
			{
				element: <ClientProducts />,
				path: menuRoutes.clients.profile.path + menuRoutes.clients.profile.products,
				access: {
					group: 'clients',
					action: 'get_clients_products'
				},
			},
			{
				element: <ClientContractsWrapper />,
				path: menuRoutes.clients.profile.path + menuRoutes.clients.profile.contracts,
				access: {
					group: 'clients',
					action: 'get_clients_contracts'
				},
			},
			{
				element: <ClientConsultancy />,
				path: menuRoutes.clients.profile.path + menuRoutes.clients.profile.consultancy,
				access: {
					group: 'clients',
					action: 'get_clients_consultancy'
				},
			},
			{
				element: <ClientInvoicesWrapper />,
				path: menuRoutes.clients.profile.path + menuRoutes.clients.profile.invoices,
				access: {
					group: 'clients',
					action: 'get_clients_invoicing'
				},
			},
			{
				element: <ClientCalls />,
				path: menuRoutes.clients.profile.path + menuRoutes.clients.profile.calls,
				access: {
					group: 'clients',
					action: 'get_clients_calls'
				},
			},
			{
				element: <ClientComments />,
				path: menuRoutes.clients.profile.path + menuRoutes.clients.profile.comments,
				access: {
					group: 'clients',
					action: 'get_clients_comments'
				},
			},
			{
				element: <ClientCreditHistory />,
				path: menuRoutes.clients.profile.path + menuRoutes.clients.profile.creditHistory,
				access: {
					group: 'clients',
					action: 'get_clients_credit_history'
				},
			},
			{
				element: <ClientActivityHistoryWrapper />,
				path: menuRoutes.clients.profile.path + menuRoutes.clients.profile.activityHistory,
				access: {
					group: 'clients',
					action: 'get_clients_activity_history'
				},
			},
			{
				element: <ClientCreate />,
				path: menuRoutes.clients.create,
				access: {
					group: 'clients',
					action: 'create_clients'
				}
			},
			{
				element: <ClientEdit />,
				path: menuRoutes.clients.edit,
				access: {
					group: 'clients',
					action: 'edit_clients'
				}
			},
		]
	},

	/******************************************************
	 * Students
	 ******************************************************/
	{
		path: menuRoutes.students.path,
		element: <StudentsWrapper />,
		sub: [
			{
				element: <StudentsListWrapper />,
				access: {
					group: 'students',
					action: 'list_students'
				},
				index: true,
			},
			{
				element: <StudentInfo />,
				path: menuRoutes.students.profile.path + menuRoutes.students.profile.info,
				access: {
					group: 'students',
					action: 'get_students'
				},
			},
			{
				element: <StudentActivityHistoryWrapper />,
				path: menuRoutes.students.profile.path + menuRoutes.students.profile.activityHistory,
				access: {
					group: 'students',
					action: 'student_changes_log'
				},
			},
			{
				element: <StudentCreate />,
				path: menuRoutes.students.create,
				access: {
					group: 'students',
					action: 'create_students'
				}
			},
			{
				element: <StudentEdit />,
				path: menuRoutes.students.edit,
				access: {
					group: 'students',
					action: 'edit_students'
				}
			}
		]
	},

	/******************************************************
	 * Consultancies
	 ******************************************************/
	{
		path: menuRoutes.consultancies.path,
		element: <ConsultanciesWrapper />,
		sub: [
			{
				element: <ConsultanciesListWrapper />,
				access: {
					group: 'consultancies',
					action: 'list_consultancies'
				},
				index: true,
			},
			{
				element: <CreateConsultancy />,
				path: menuRoutes.consultancies.create,
				access: {
					group: 'consultancies',
					action: 'create_consultancies'
				}
			},
			{
				element: <EditConsultancy />,
				path: menuRoutes.consultancies.edit,
				access: {
					group: 'consultancies',
					action: 'edit_consultancies'
				}
			}
		]
	},

	/******************************************************
	 * Tutors admin panel
	 ******************************************************/
	{
		path: '',
		element: <TutorsLayoutWrapper />,
		sub: [
			{
				element: <MyTutorials />,
				path: menuRoutes.tutor_panel.tutorials,
			},
			{
				element: <ListMyInvoicesWrapper />,
				path: menuRoutes.tutor_panel.invoices,
			},
			{
				element: <MyCalendar />,
				path: menuRoutes.tutor_panel.calendar,
			},
			{
				element: <ListMyDocumentsWrapper />,
				path: menuRoutes.tutor_panel.documents,
			},
			{
				element: <TutorProfile />,
				path: menuRoutes.tutor_panel.profile,
				index: true,
			},
		]
	},

	/******************************************************
	 * General configuration
	 ******************************************************/
	{
		path: '',
		element: <GeneralConfigurationWrapper />,
		sub: [
			{
				element: <ConfigListWrapper />,
				path: menuRoutes.generalConfiguration.path + menuRoutes.generalConfiguration.educationalLevels,
			},
			{
				element: <ConfigListWrapper />,
				path: menuRoutes.generalConfiguration.path + menuRoutes.generalConfiguration.professionalCategories,
			},
			{
				element: <ConfigListWrapper />,
				path: menuRoutes.generalConfiguration.path + menuRoutes.generalConfiguration.quotationGroups,
			},
			{
				element: <ConfigListWrapper />,
				path: menuRoutes.generalConfiguration.path + menuRoutes.generalConfiguration.campaigns,
			},
		]
	},
	/******************************************************
	 * Contracts
	 ******************************************************/
	{
		path: menuRoutes.contracts.path,
		element: <ContractsLayoutWrapper />,
		sub: [
			{
				element: <ContractsListWrapper />,
				path: menuRoutes.contracts.path,
				access: {
					group: 'contracts',
					action: 'list_contracts'
				},
				index: true,
			},
			{
				element: <ContractCreate />,
				path: menuRoutes.contracts.create,

			},
			{
				element: <ContractEdit />,
				path: menuRoutes.contracts.edit,
				access: {
					group: 'contracts',
					action: 'edit_contracts'
				}
			},
			{
				element: <ContractInfo />,
				path: menuRoutes.contracts.profile.path + menuRoutes.contracts.profile.info,
				access: {
					group: 'contracts',
					action: 'get_contracts'
				},
			},
			{
				element: <ContractInvoicing />,
				path: menuRoutes.contracts.profile.path + menuRoutes.contracts.profile.invoicing,
				access: {
					group: 'contracts',
					action: 'get_contract_invoicing'
				},
			},
			{
				element: <ContractDocuments />,
				path: menuRoutes.contracts.profile.path + menuRoutes.contracts.profile.documents,
				access: {
					group: 'contracts',
					action: 'get_contracts'
				},
			},
			{
				element: <ContractStatesHistory />,
				path: menuRoutes.contracts.profile.path + menuRoutes.contracts.profile.statesHistory,
				access: {
					group: 'contracts',
					action: 'show_contract_status_history'
				},
			},
			{
				element: <ContractChangelogsWrapper />,
				path: menuRoutes.contracts.profile.path + menuRoutes.contracts.profile.changelogs,
				access: {
					group: 'contracts',
					action: 'contract_changes_log'
				},
			},
			{
				element: <ContractComments />,
				path: menuRoutes.contracts.profile.path + menuRoutes.contracts.profile.comments,
				access: {
					group: 'contracts',
					action: 'get_contract_comments'
				},
			},
		]
	},

	/******************************************************
	 * Settings
	 ******************************************************/
	{
		path: menuRoutes.settings.path,
		element: <SettingsWrapper />,
		sub: [
			{
				element: <ContractsSettingsPage />,
				path: menuRoutes.settings.path + menuRoutes.settings.contracts,
				// access: {
				//     group: 'settings',
				//     action: 'admin_settings_contracts'
				// },
			},
			{
				element: <InvoicingsSettingsPage />,
				path: menuRoutes.settings.path + menuRoutes.settings.invoices,
				// access: {
				//     group: 'settings',
				//     action: 'admin_settings_invoices'

			},
			{
				element: <HolidaysSettingsPage />,
				path: menuRoutes.settings.path + menuRoutes.settings.holidays,
				// access: {
				//     group: 'settings',
				//     action: 'admin_settings_holidays'
				// },
			}
		]
	},
];

const contents = [...protectedRoutes];
export default contents;