import React, { useCallback, useState } from 'react';
import useHandleErrors from '../../../hooks/useHandleErrors';
import { useFiltersPR } from '../../../components/providers/FiltersProvider';
import { StudentService } from '../../../services/student/StudentService';
import useFetch from '../../../hooks/useFetch';
import { toast } from 'sonner';
import Page from '../../../layout/Page/Page';
import CustomTable from '../../../components/table/CustomTable';
import { ActionIcon, Switch, Tooltip } from 'rizzui';
import { Link } from 'react-router-dom';
import { menuRoutes } from '../../../router/menu';
import { MdOutlineModeEditOutline } from 'react-icons/md';
import DeletePopover from '../../../components/buttons/DeletePopover';
import { FilterDrawerView } from '../../../components/table/components/TableFilter';
import StudentsFilters from './StudentsFilters';
import { StudentsApiResponse } from '../../../type/entities/student-type';
import { HiOutlineEye } from 'react-icons/hi2';
import { usePrivilege } from '../../../components/priviledge/PriviledgeProvider';
import { Text } from 'rizzui';

interface StudentsListProps { }

const columnOrder = [
    'id',
    'name',
    'firstName',
    'lastName',
    'nif',
    'gender',
    'birthDate',
    'disability',
    'genderViolenceVictim',
    'terrorismVictim',
    'client',
    'other',
    'active',
    'createdAt',
    'updatedAt',
];

const StudentsList: React.FC<StudentsListProps> = ({ }) => {

    const { userCan } = usePrivilege();
    const { handleErrors } = useHandleErrors();
    const { filters, updateFilters, updateFilterOrder, updatePage, updatePageSize, resetFilters } = useFiltersPR();
    const service = new StudentService();

    const [changingStatus, setChangingStatus] = useState<string[]>([]);
    const [openFilters, setOpenFilters] = useState<boolean>(false);

    const [data, loading, error, refetch] = useFetch(useCallback(async () => {
        const response = await service.listStudents(filters);
        return response.getResponseData() as StudentsApiResponse;
    }, [filters]));

    const toggleState = async (id: string, status: boolean) => {
        try {
            setChangingStatus([...changingStatus, id]);
            const response = (await service.toggleStudentState(id, status)).getResponseData();
            if (response.success) {
                setChangingStatus(changingStatus.filter((item) => item !== id));
                refetch();
                toast.success(status ? "Se ha desactivado el empleado" : "Se ha activado el empleado");
            } else {
                setChangingStatus(changingStatus.filter((item) => item !== id));
                toast.error(response.message);
            }
        } catch (error: any) {
            setChangingStatus(changingStatus.filter((item) => item !== id));
            toast.error(error.message);
        }
    };

    const handleDelete = async (id: string) => {
        const response = await (await service.deleteStudent(id)).getResponseData();
        if (response.success) {
            toast.success('Empleado eliminado correctamente');
            refetch();
        } else {
            handleErrors(response);
        }
    };

    const handleMultiDelete = async (ids: string[]) => {
        const response = await (await service.deleteMultiStudents(ids)).getResponseData();
        if (response.success) {
            toast.success('Empleados eliminados correctamente');
            refetch();
        } else {
            handleErrors(response);
        }
    };

    /**
     * Order the data to match the column order
     */
    const orderedData = () => data?.data.map((row: any) => {
        const orderedRow: any = {};
        columnOrder.forEach((key) => {
            orderedRow[key] = row[key];
        });
        return orderedRow;
    });

    return (
        <Page container='fluid'>
            {data !== undefined && (
                <CustomTable
                    id={'students-table'}
                    columnOrder={columnOrder}
                    data={orderedData()}
                    isLoading={loading}
                    columnsNotShown={['firstName', 'lastName', 'disability', 'genderViolenceVictim', 'terrorismVictim']}
                    overrideColumns={[
                        {
                            key: 'name',
                            render: (row: any) => {
                                return (
                                    userCan('get_students', 'students')
                                        ? (
                                            <Link to={`${menuRoutes.students.path}/${row.row.original.id}/profile${menuRoutes.students.profile.info}`}>
                                                <Text className='font-bold text-primary'>{`${row.row.original.name} ${row.row.original.firstName || ''} ${row.row.original.lastName || ''}`}</Text>
                                            </Link>
                                        )
                                        : <Text className='font-bold text-primary'>`${row.row.original.name} ${row.row.original.firstName || ''} ${row.row.original.lastName || ''}`</Text>
                                )
                            },
                        },
                        {
                            key: 'gender',
                            render: (row: any) => {
                                return ((row.row.original.gender === 'male') ? 'Masculino' : (row.row.original.gender === 'female' ? 'Femenino' : 'N/A'));
                            },
                        },
                        {
                            key: 'client',
                            render: (row: any) => {
                                return row.row.original.client ? row.row.original.client.companyName : 'N/A';
                            },
                        },
                        {
                            key: 'other',
                            render: (row: any) => {
                                return (
                                    <ul className='list-disc text-nowrap'>
                                        {row.row.original.disability && <li>Discapacidad</li>}
                                        {row.row.original.genderViolenceVictim && <li>Victima de violencia de género</li>}
                                        {row.row.original.terrorismVictim && <li>Victima de terrorismo</li>}
                                        {!row.row.original.genderViolenceVictim && !row.row.original.terrorismVictim && !row.row.original.disability && 'N/A'}
                                    </ul>
                                );
                            },
                        },
                        {
                            key: 'active',
                            render: (row: any) => {
                                return (
                                    <Tooltip
                                        size="sm"
                                        content={row.row.original.active ? 'Desactivar cliente' : 'Activar cliente'}
                                        placement="top"
                                        color="invert"
                                    >
                                        <Switch
                                            id={row.row.original.id}
                                            checked={row.row.original.active}
                                            onChange={() => toggleState(row.row.original.id, row.row.original.active)}
                                            switchKnobClassName='bg-secondary'
                                        />
                                    </Tooltip>
                                );
                            },
                        },
                    ]}
                    actions={[
                        {
                            label: 'View',
                            permissions: { group: 'students', permission: 'get_students' },
                            render: (row: any) => {
                                return (
                                    <Tooltip
                                        size="sm"
                                        content={'Ver perfil'}
                                        placement="top"
                                        color="invert"
                                    >
                                        <Link
                                            to={`${menuRoutes.students.path}/${row.id}/profile${menuRoutes.students.profile.info}`}>
                                            <ActionIcon
                                                as="span"
                                                size="sm"
                                                variant="outline"
                                                className="hover:!border-gray-900 hover:text-gray-700"
                                            >
                                                <HiOutlineEye className="h-4 w-4" size={20} />
                                            </ActionIcon>
                                        </Link>
                                    </Tooltip>
                                );
                            },
                        },
                        {
                            label: 'Edit',
                            permissions: { group: 'students', permission: 'edit_students' },
                            render: (row: any) => {
                                return (
                                    <Tooltip
                                        size="sm"
                                        content={'Editar empleado'}
                                        placement="top"
                                        color="invert"
                                    >
                                        <Link to={`${menuRoutes.students.path}/${row.id}/edit`}>
                                            <ActionIcon
                                                as="span"
                                                size="sm"
                                                variant="outline"
                                                className="hover:!border-gray-900 hover:text-gray-700"
                                            >
                                                <MdOutlineModeEditOutline className="h-4 w-4" size={20} />
                                            </ActionIcon>
                                        </Link>
                                    </Tooltip>
                                );
                            },
                        },
                        {
                            label: 'Delete',
                            permissions: { group: 'students', permission: 'delete_students' },
                            render: (row: any) => {
                                return (
                                    <Tooltip
                                        size="sm"
                                        content={'Eliminar'}
                                        placement="top"
                                        color="invert"
                                    >
                                        <div>
                                            <DeletePopover
                                                title={`Eliminar empleado`}
                                                description={`¿Estás seguro de que deseas eliminar a ${row.name}?`}
                                                onDelete={() => handleDelete(row.id)}
                                            />
                                        </div>
                                    </Tooltip>
                                );
                            },
                        },
                    ]}
                    handleMultipleDelete={handleMultiDelete}
                    filters={filters}
                    updateFilters={updateFilters}
                    updateFilterOrder={updateFilterOrder}
                    defaultOrder={filters.filter_order || undefined}
                    paginationData={{
                        pageSize: filters.limit,
                        currentPage: filters.page,
                        pageCount: (data as StudentsApiResponse) ? data.lastPage : 1,
                        totalCount: data?.totalRegisters,
                        handlePagination: updatePage,
                        handlePerPage: updatePageSize,
                    }}
                    toggleFilters={() => setOpenFilters(!openFilters)}
                />
            )}

            <FilterDrawerView isOpen={openFilters} setOpenDrawer={setOpenFilters} drawerTitle={'Filtros Empleados'}>
                <StudentsFilters filters={filters} updateFilters={updateFilters} resetFilters={resetFilters} />
            </FilterDrawerView>
        </Page>
    );
};

export default StudentsList;