import { Navigate, Route, Routes } from 'react-router-dom';
import contents from './routes';
import ProtectedRoute from './ProtectedRoute';
import AppLayout from '../pages/_layout/AppLayout';
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import Login from '../pages/auth/Login';
import Page404 from '../pages/auth/Page404';
import ForgotPassword from '../pages/auth/ForgotPassword';
import ResetPassword from '../pages/auth/ResetPassword';
import TutorLogin from '../pages/auth/tutor/TutorLogin';
import { menuRoutes } from './menu';
import ForgotPasswordTutor from '../pages/auth/tutor/TutorForgotPassword';
import TutorResetPassword from '../pages/auth/tutor/TutorResetPassword';
import { useEffect } from 'react';
import { UserService } from '../services/user/userService';

const AppRouter = () => {

  const user = useSelector((state: RootState) => state.auth);
  const tutor = useSelector((state: RootState) => state.tutor);

  const renderRoute = (page: any, index: number, access: boolean = true) => {
    page.element = <ProtectedRoute component={page.element} access={access && page.access} />;
    return (
      <Route key={index} {...page}>
        {page.sub?.map((subPage: any, index: number) => renderRoute(subPage, index))}
      </Route>
    );
  };

  useEffect(() => {
    if (user.isAuthenticated && user?.user?.id) {
      (new UserService()).getUserById(user?.user?.id);
    }
  }, []);

  return (
    <Routes>
      <Route element={<AppLayout />}>
        {contents.map((page, index) => renderRoute(page, index))}
      </Route>

      <Route path={menuRoutes.auth.login} element={<Login />} />
      <Route path={menuRoutes.auth.forgot_password} element={<ForgotPassword />} />
      <Route path={menuRoutes.auth.reset_password} element={<ResetPassword />} />
      <Route path={menuRoutes.tutor_panel.login} element={<TutorLogin />} />
      <Route path={menuRoutes.tutor_panel.forgot_password} element={<ForgotPasswordTutor />} />
      <Route path={menuRoutes.tutor_panel.reset_password} element={<TutorResetPassword />} />
      {user.isAuthenticated && <Route path="/" element={<Navigate to={menuRoutes.dashboard.path} />} />}
      {tutor.isAuthenticated && <Route path="/" element={<Navigate to={menuRoutes.tutor_panel.profile} />} />}
      {(!user.isAuthenticated && !tutor.isAuthenticated) && <Route path="*" element={<Navigate to={menuRoutes.auth.login} />} />}
      {(user.isAuthenticated || tutor.isAuthenticated) && <Route path="*" element={<Page404 />} />}
    </Routes>
  );
};

export default AppRouter;